import React from 'react'
import TableTrips from './TableTrips'
import Ticket from './Ticket'

function Container() {
  return (
    <div className=' flex flex-row justify-center items-center mt-11'>
        <div className='w-3/5 border shadow-mdn h-[45rem]' >
          <TableTrips />
        </div>
        <div  className='w-2/5 border ml-4 shadow-md h-[45rem]'>
          <Ticket />
        </div>
    </div>
  )
}

export default Container
