import { Toaster, toast } from "react-hot-toast";
import { getAllAgreements } from "../../../../Utils/Requests";
import { useMessage } from "../../../../hooks/MessageContext";
import GetAgreementsRow from "./GetAgreementsRow";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";

function GetAgreements() {
  const [data, setData] = useState([]);
  const token = Cookies.get("token");
  useEffect(() => {
    (async () => {
      try {
        const data = await getAllAgreements(token);
        setData(data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const { message, setMessage } = useMessage();

  if (message) {
    toast.success(message);
    setMessage(null);
  }

  const resetData = (id) => {
    let res = data.filter((agreement) => agreement.agreement_id !== id);
    setData(res);
  };
  return (
    <>
      <div>
        <Toaster position="bottom-right" reverseOrder={false} />
      </div>
      {/* search bar */}
      <form className="mt-3 pr-4 ">
        <div className="my-2 flex justify-center items-center gap-4">
          <div>
            <select
              id="countries"
              class="bg-gray-50 border p-4 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
            >
              <option disabled selected>
                Choose a Option
              </option>
              <option value="Donnateur">agreement uid</option>
              <option value="Donnateur">Expiration Date</option>
              <option value="Donnateur">Bus ID</option>
              <option value="Donnateur">Bus Company ID</option>
            </select>
          </div>
          <div>
            <label
              htmlFor="default-search"
              className="mb-2 text-sm font-medium text-gray-900 sr-only"
            >
              Search
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Search for Bus"
                required
              />
              <button
                type="submit"
                className="text-white absolute right-2.5 bottom-2.5 bg-red-500 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </form>
      {/* end of search bar  */}
      <div class="relative overflow-x-auto mt-4">
        <table class="w-full text-sm text-left text-gray-500">
          <thead class="text-xs  text-gray-700 uppercase">
            <tr>
              <th scope="col" class="px-6 py-3 ">
                agreement uid
              </th>
              <th scope="col" class="px-6 py-3 ">
                Expiration Date
              </th>
              <th scope="col" class="px-6 py-3 ">
                Buses license plate
              </th>
              <th scope="col" class="px-6 py-3 ">
                Bus Company
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((bus, index) => (
              <GetAgreementsRow
                key={index}
                resetData={resetData}
                {...bus}
                setMessage={setMessage}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default GetAgreements;
