
import {Outlet, Navigate} from 'react-router-dom'
import * as React from 'react';
import Cookies from 'js-cookie';

const ProtectedRouteCashier = () => {
    

let role= Cookies.get("role")
return(
    role === 'cashier'  ? <Outlet/> : <Navigate to="/home"/>
)
}
export default ProtectedRouteCashier