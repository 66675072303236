import React from 'react'

function CustomButton(props) {
  return (
    <button
        type={props.type}
        onClick={props.onClick}
        className=" inline-block  rounded border border-[#EA022E] bg-[#EA022E] px-16 py-3.5 text-sm font-medium text-white hover:bg-[#ce0026]">
          {props.content}
        </button>
  )
}
export default CustomButton
