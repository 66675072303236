import { Outlet , Navigate } from "react-router-dom";
import * as React from 'react'
import Cookies from "js-cookie";


const ProtectedRouteCashierAdvanced = () => {
    const role = Cookies.get("role")
    return (
        role === "cashier_advanced" ? <Outlet/> : <Navigate to="/cashier" />
    )
}


export default ProtectedRouteCashierAdvanced;