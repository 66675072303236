import React from 'react'
import TicketFromFdr from './TicketFromFdr'
import TripsFromFdr from './TripsFromFdr'

function Container(props) {
  return (
    <div>
    <div className=' flex flex-row justify-center items-center px-2  mt-3'>
      <div className='w-4/6 border rounded-md   h-[41rem] bg-white shadow-lg' >
        <TripsFromFdr  rowSelected={props.rowSelected} />
      </div>
      <div  className='w-2/6 border ml-4 rounded-md shadow-lg h-[41rem] bg-white'>
        <TicketFromFdr  rowSelected={props.rowSelected} />
      </div>
  </div>
  </div>
  )
}

export default Container
