import React, { useState, useEffect, useContext } from 'react'
import CustomButton from '../CustomButton'
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { GetListOfCities, SearchTrips } from '../../Utils/Requests';
import Cookies from 'js-cookie';
import dayjs from "dayjs";
import TripsLists from '../../hooks/TripsCashierAdvanced';
import TripIdCashierAdvancedContext from '../../hooks/TripIdCashierAdvanced';


function SearchBarCashierAdvanced() {

  const { setListOfTrips , setSeatsContext } = useContext(TripsLists)
  const { setTripIdCashierAdvanced } = useContext(TripIdCashierAdvancedContext)

  function dateChang(d) {
    const timestamp = Date.parse(d);
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
  // state's
  const token = Cookies.get('token')
  const [city, setCity] = useState("");
  const [datetime, setDateTime] = useState(dayjs());
  const [dateBestFormat, setDateBestFormat] = useState(dateChang(datetime))
  const [seats, setSeats] = useState(1);
  const [cities, setCities] = useState([]);
  // retrieve data and return state :
  function isOptionEqualToValue(option, value) {
    return option.title === value.title;
  }
  const handleOnChange = (event, value) => {
    setCity(value?.value);
  }
  // Handle Number of seats :
  const handleSeats = (e) => {
    setSeats(e.target.value)
  }

  useEffect(() => {
    try {
      GetListOfCities(token)
        .then((data) => {
          const options = data?.map((cit) => ({ label: cit, value: cit }));
          setCities(options);
        })
        .catch(() => {
          setCities();
        });
    } catch (error) {
      setCities()
    }
  }, []);

  const GetTrips = () => {
    setTripIdCashierAdvanced('')
    setSeatsContext(seats)
    try {
      SearchTrips(city, dateBestFormat,token,seats).then((data) => {
        setListOfTrips(data)
      }).catch(() => {
        setListOfTrips([])
      })
    } catch (error) {
      setListOfTrips([])
    }
  }


  const isBeforeToday = (date) => {
    return date.isBefore(dayjs(), 'day');
  };


  return (
    <div className='mt-5 bg-white py-5 shadow-lg rounded-lg flex flex-row justify-center items-center gap-11'>
      <div className="flex flex-row items-center  mr-6 ">
        <Autocomplete
          disablePortal
          id="cities"
          isOptionEqualToValue={isOptionEqualToValue}
          onChange={handleOnChange}
          options={cities}
          sx={{ width: 200 }}
          renderInput={(options) => (
            <TextField {...options} label="Destinations" />
          )}
        />
      </div>
      {/* start  */}
      <span>
        <svg
          width="4"
          height="72"
          viewBox="0 0 4 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M2 0L2 72" stroke="#EA022E" stroke-width="3" />
        </svg>
      </span>
      {/* end */}
      {/* Date */}
      <div className="flex flex-row items-center mx-4">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Date"
            value={datetime}
            onChange={(newValue) => setDateBestFormat(dateChang(newValue))}
            shouldDisableDate={isBeforeToday}
          />
        </LocalizationProvider>
      </div>
      {/* end Date input */}
      <span>
        <svg
          width="4"
          height="72"
          viewBox="0 0 4 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M2 0L2 72" stroke="#EA022E" stroke-width="3" />
        </svg>
      </span>
      {/* seats */}
      <div className="flex flex-row items-center mx-4">
        <TextField
          onChange={handleSeats}
          label="Number of Seats"
          type="number"
          sx={{ width: 150 }}
          inputProps={{ min: 1, max: 4 }}
        />
      </div>
      {/* seats input */}
      <span>
        <svg
          width="4"
          height="72"
          viewBox="0 0 4 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M2 0L2 72" stroke="#EA022E" stroke-width="3" />
        </svg>
      </span>
      {/* Search btn */}
      <div className="flex flex-row items-center mx-2 ml-4">
        <CustomButton
          type="submit"
          content="Chercher"
          onClick={GetTrips}
        />
      </div>
      {/* end btn search */}
      {/* Search btn */}
    </div>
  )
}

export default SearchBarCashierAdvanced
