import React, { useEffect } from 'react'
import BarAdd from '../../../../components/Admin/BarAdd'
import FormWrapper from '../../../../components/Admin/FormWrapper'
import CustomButton from '../../../../components/CustomButton'
import CustomInput from '../../../../components/CustomInput'
import CustomLabel from '../../../../components/CustomLabel'
import { useParams , useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { UpdateRoute } from '../../../../Utils/Requests'
import Cookies from 'js-cookie'
import Alert from '../../../../components/Alert'
import {UpdateSingleUser ,  GetAllBusStation , GetSingleUser } from '../../../../Utils/Requests'
// UpdateSingleUser(id,token,data) && GetAllBusStation(token) && GetAllUsers(token)


function UpdateUser() {
  const { id } = useParams()
  const token = Cookies.get('token')
  // State to set Data Bus Station : 
  const [busStation,setBusStation] = useState([])
  // State's To get Get data :
  const [first_name, setFirstName] = useState('')
  const [last_name, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [role, setRole] = useState('')
  const [bus_station_id,setBusStationId] = useState(null)
  // message and Error States :
  const [error,setError] = useState(false)
  const [message, setMessage] = useState('')

    // Get Data for BUS STATION
    const getBus = () => {
        GetAllBusStation(token).then((data) => {
            setBusStation(data)
        })}

    // To get ID instead of name :
    const idByName = busStation.reduce((acc, obj) => {
        acc[obj.name] = obj.bus_station_id;
        return acc;
      }, {});
      function getIdByName(name) {
        return idByName[name];
      }
    // Get Data for User to set it as values : 
    useEffect(() => {
        try {
            GetSingleUser(id,token).then((data) => {
                console.log(data);
                setFirstName(data.first_name)
                setLastName(data.last_name)
                setEmail(data.email)
                setRole(data.role)
                setBusStationId(data.bus_station_name)
            }).catch((err) => {
                console.log(err);
            })
        } catch (error) {
                console.log(error);
        }
        getBus()
    }, [])


    // Array of Roles : 
  const Roles = 
  [
    {role: "cashier"},
    {role: "superadmin"},
    {role: "chef-de-gare"}
  ]


  // For Navigation For A succes Update : 
  const navigate = useNavigate()


  const updateHandler = (e) => {
    e.preventDefault()
    const data = {
        first_name,
        last_name,
        email,
        role,
        bus_station_id
    }
    if(Object.values(data).some((field) => field === " " || field === null || field === undefined)){
        setError(true)
        setMessage('please fill all fields')
        return
      }
    try {
        UpdateSingleUser(id,token,data).then(() => {
          setError(false)
          navigate('/users')
        }).catch(() => {
          setMessage('Server Error , please try Again or verify Information !')
          setError(true)
        })
    } catch (error) {
          setMessage('Server Error , please try Again or verify Information !')
          setError(true)
    }  
  }



  return (
    <>
    <BarAdd title="Update User" />
    {error && <Alert className="flex mx-auto p-4 mb-4 mt-7 w-64 text-sm text-red-800 rounded-lg bg-red-100" message={message}/>}
    <FormWrapper 
    title="Update User"
    Children={<>
    <CustomLabel label="FIRST NAME" />
    <CustomInput
    onChange={(e) => {setFirstName(e.target.value)}}
    id="FIRST NAME"
    name="FIRST NAME"
    type="text"
    value={first_name}
    />
    <CustomLabel label="LAST NAME" />
    <CustomInput
    onChange={(e) => {setLastName(e.target.value)}}
    id="LAST NAME"
    name="LAST NAME"
    type="text"
    value={last_name}
    />
    <CustomLabel label="EMAIL" />
    <CustomInput
    onChange={(e) => {setEmail(e.target.value)}}
    id="EMAIL"
    name="EMAIL"
    type=""
    value={email}
    />
    <CustomLabel label="ROLE" />
    <select onChange={(e) => {setRole(e.target.value)}} className="block  text-black appearance-none w-full bg-white border  hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
    <option selected disabled>{role}</option>
    {Roles.map((role) => (
                <option value={role.role}>
                    {role.role}
                </option>
              ))}
              </select>
    <CustomLabel label="BUS STATION" />
    <select onChange={(e) => {setBusStationId(getIdByName(e.target.value))}} className="block  text-black appearance-none w-full bg-white border  hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
             <option selected disabled>{bus_station_id}</option>
             {busStation.map((busstation) => (
                  <option  value={busstation.name}>
                   {busstation.name}
              </option>
              ))}
              </select>
    <CustomButton
    onClick={updateHandler}
    type="submit"
    content="Update"
    />
    </>}
    />
    </>
  )
}

export default UpdateUser
