import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BarAdd from "../../../../components/Admin/BarAdd";
import FormWrapper from "../../../../components/Admin/FormWrapper";
import Alert from "../../../../components/Alert";
import CustomButton from "../../../../components/CustomButton";
import CustomInput from "../../../../components/CustomInput";
import CustomLabel from "../../../../components/CustomLabel";
import Cookies from "js-cookie";
import { GetAllCompanies, getBusById, updateBus } from "../../../../Utils/Requests";
import { useMessage } from "../../../../hooks/MessageContext";
import { Autocomplete, TextField } from "@mui/material";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import {
  InputLabel,
  MenuItem,
  Select,
  FormControl
} from "@mui/material";


const PutBus = () => {
  const [isLoaded, setIsLoaded] = useState(true);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("Something went wrong!");
  const [data, setData] = useState({});
  const [license_plate, setLicense_plate] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [capacity, setCapacity] = useState(null);
  const [expiry_date_of_insurance, setExpiry_date_of_insurance] = useState("");
  const [
    expiry_date_of_technical_inspection,
    setExpiry_date_of_technical_inspection,
  ] = useState("");
  const [expiry_date_of_registration, setExpiry_date_of_registration] =
    useState("");
  const [rfid_tag, setRfid_tag] = useState("");
  const [bus_company_id, setBus_company_id] = useState(null);
  const [bus_companies, setBus_companies] = useState([]);
  const [is_numeral , setIsNumeral] = useState(null)

  const { id } = useParams();
  const token = Cookies.get("token");
  const { setMessage: setSuccessMessage } = useMessage();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const bus = await getBusById(id, token);
      const bus_companies = await GetAllCompanies(token);
      setBus_companies(bus_companies);
      setData(bus);
      setLicense_plate(bus.license_plate);
      setMake(bus.make);
      setModel(bus.model);
      setCapacity(bus.capacity);
      setExpiry_date_of_insurance(bus.expiry_date_of_insurance.slice(0, 10));
      setExpiry_date_of_technical_inspection(
        bus.expiry_date_of_technical_inspection.slice(0, 10)
      );
      setExpiry_date_of_registration(
        bus.expiry_date_of_registration.slice(0, 10)
      );
      setRfid_tag(bus.rfid_tag);
      setBus_company_id(+bus.bus_company_id);
      setIsNumeral(bus.is_numeral)

      setIsLoaded(false);
    })();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      license_plate,
      make,
      model,
      capacity,
      expiry_date_of_insurance,
      expiry_date_of_technical_inspection,
      expiry_date_of_registration,
      rfid_tag,
      bus_company_id,
      is_numeral
    };
    console.log(data);
    if (Object.values(data).some((field) => field === "" || field === null)) {
      setMessage("Please fill all the fields");
      setError(true);
      return;
    }
    
    try {
      await updateBus(id, data, token);
      setSuccessMessage("Bus has been updated successfully");
      navigate("/bus");
    } catch (error) {
      console.log(error);
      setMessage("Something went wrong!");
      setError(true);
    }
  };

  if (isLoaded) return <LoadingSpinner open />;

  return (
    <>
      <BarAdd title="Add Bus" />
      <FormWrapper
        title="Update Bus"
        Children={
          <>
            {error && (
              <Alert
                className="flex mx-auto p-4 mb-4 mt-7 w-64 text-sm text-red-800 rounded-lg bg-red-100"
                message={message}
              />
            )}
            <CustomLabel label="license plate" />
            <CustomInput
              id="license plate"
              name="license plate"
              type="text"
              placeholder="license plate"
              value={license_plate}
              onChange={(e) => setLicense_plate(e.target.value)}
            />
            <CustomLabel label="make" />
            <CustomInput
              id="make"
              name="make"
              type="text"
              placeholder="make"
              value={make}
              onChange={(e) => setMake(e.target.value)}
            />
            <CustomLabel label="model" />
            <CustomInput
              id="model"
              name="model"
              type="text"
              placeholder="model"
              value={model}
              onChange={(e) => setModel(e.target.value)}
            />
            <CustomLabel label="capacity" />
            <CustomInput
              id="capacity"
              name="capacity"
              type="text"
              placeholder="capacity"
              value={capacity}
              onChange={(e) => setCapacity(e.target.value)}
            />
            <CustomLabel label="expiry date of insurance" />
            <CustomInput
              id="expiry date of insurance"
              name="expiry date of insurance"
              type="date"
              placeholder="expiry date of insurance"
              value={expiry_date_of_insurance}
              onChange={(e) => setExpiry_date_of_insurance(e.target.value)}
            />
            <CustomLabel label="expiry date of technical inspection" />
            <CustomInput
              id="expiry date of technical inspection"
              name="expiry date of technical inspection"
              type="date"
              placeholder="expiry date of technical inspection"
              value={expiry_date_of_technical_inspection}
              onChange={(e) =>
                setExpiry_date_of_technical_inspection(e.target.value)
              }
            />
            <CustomLabel label="expiry date of registration" />
            <CustomInput
              id="expiry date of registration"
              name="expiry date of registration"
              type="date"
              placeholder="expiry date of registration"
              value={expiry_date_of_registration}
              onChange={(e) => setExpiry_date_of_registration(e.target.value)}
            />
            <CustomLabel label="Rfid tag" />
            <CustomInput
              id="Rfid tag"
              name="Rfid tag"
              type="text"
              placeholder="Rfid tag"
              value={rfid_tag}
              onChange={(e) => setRfid_tag(e.target.value)}
            />
            <CustomLabel label="bus company" />
            <Autocomplete
              onChange={(event, value) =>
                value?.id ? setBus_company_id(value.id) : setBus_company_id(null)
              }
              options={bus_companies}
              defaultValue={bus_companies.find(
                (company) => company.bus_company_id === bus_company_id
              )}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField {...params} label="Select Bus company" />
              )}
            />

            <CustomLabel label="is_Numeral" />
            <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{is_numeral ? "true" : "false"}</InputLabel>
                  <Select
                    onChange={(e) =>
                      e.target.value ? setIsNumeral(true) : setIsNumeral(false)
                    }
                  >
                    <MenuItem value={true}>true</MenuItem>
                    <MenuItem value={false}>false</MenuItem>
                  </Select>
            </FormControl>

            <CustomButton
              type="submit"
              content="Update"
              onClick={handleSubmit}
            />
          </>
        }
      />
    </>
  );
};

export default PutBus;
