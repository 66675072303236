import React from 'react'
import './Loader.css'

function Loader() {
    return (
        <div className='h-[700px] flex  items-center mx-auto'>
        <div className="spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        </div>
    ) 
}

export default Loader
