import React from 'react'
import BarAdd from '../../components/Admin/BarAdd'
import GetAgreements from '../../components/Admin/data/GET/GetAgreements'


function Agreements() {
  return (
    <div>
      <BarAdd
        to="/addagreement"
        title="Agreements List"
        content="Add Agreement +"
        style={"px-4 bg-white mr-5 py-2 rounded-lg"}
      />
      <GetAgreements />
    </div>
  )
}

export default Agreements
