import { useState, createContext } from "react";

const TripIdContext = createContext();

const TripProvider = ({ children }) => {
  const [TripId, setTripId] = useState("");
  const [showData, setShowData] = useState(false);
  const [quantity, setQuantity] = useState([]);
  const [shouldRefetch, setShouldRefetch] = useState(false);

  return (
    <TripIdContext.Provider
      value={{
        TripId,
        setTripId,
        showData,
        setShowData,
        quantity,
        setQuantity,
        shouldRefetch,
        setShouldRefetch,
      }}
    >
      {children}
    </TripIdContext.Provider>
  );
};

export default TripIdContext;
export { TripProvider };
