import { Outlet } from "react-router-dom";
import Sidebar from "../components/Admin/Sidebar";

const LayoutDash = () => {
  return (
    <div className="flex bg-gray-50">
      <Sidebar />
      <div
        className=" w-full min-h-screen"
        style={{ marginLeft: "17rem", paddingTop: "2rem" }}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default LayoutDash;
