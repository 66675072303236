import React from "react";

const GetTripRow = ({
  trip_id,
  bus_id,
  bus_company_name,
  fdr_id,
  route_id,
  allowed_route_id,
  departure_city,
  arrival_city,
  departure_date,
  departure_time,
  arrival_time,
  status,
  quai,
  availble_seats,
}) => {
  const handleEdit = () => {};
  const handleDelete = () => {};
  return (
    <tr className="bg-white border-b">
      <th
        scope="row"
        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
      >
        {trip_id}
      </th>
      <td className="px-6 py-4">{bus_id}</td>
      <td className="px-6 py-4">{bus_company_name}</td>
      <td className="px-6 py-4">{fdr_id}</td>
      <td className="px-6 py-4">{route_id}</td>
      <td className="px-6 py-4">{allowed_route_id}</td>
      <td className="px-6 py-4">{departure_city}</td>
      <td className="px-6 py-4">{arrival_city}</td>
      <td className="px-6 py-4">{departure_date.slice(0, 10)}</td>
      <td className="px-6 py-4">{departure_time.slice(0, 5)}</td>
      <td className="px-6 py-4">{arrival_time.slice(0, 5)}</td>
      <td className={`px-6 py-4 ${status ? "text-green-500" : "text-red-500"}`}>{status ? "True" : "False"}</td>
      <td className="px-6 py-4">{quai}</td>
      <td className="px-6 py-4">{availble_seats}</td>
    </tr>
  );
};

export default GetTripRow;
