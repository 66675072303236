import { createContext , useContext , useState } from 'react'

const GareName = createContext()

const GareProvider = ({ children }) => {
    const [nameGare , setNameGare] = useState('')
    return (
        <GareName.Provider value={{nameGare , setNameGare}}>
            {children}
        </GareName.Provider>
    )
}


export default GareName;
export {GareProvider};
