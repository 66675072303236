import React from 'react'
import BarAdd from '../../components/Admin/BarAdd'
import GetRoutes from '../../components/Admin/data/GET/GetRoutes'

function RoutesPage() {
  return (
    <div>
      <BarAdd 
        to="/AddRoutes"
        title="Routes List"
        content="Add Route +"
        style={"px-4 bg-white mr-5 py-2 rounded-lg"}
      />
      <GetRoutes />
    </div>
  )
}

export default RoutesPage
