import React from 'react'
import BarAdd from '../../components/Admin/BarAdd'
import GetBusCompanies from '../../components/Admin/data/GET/GetBusCompanies'

function BusCompanies() {
  return (
    <div>
      <BarAdd to="/addbuscompanies" title="Bus Companies List" content="Add Bus Companies +" style={"px-4 bg-white mr-5 py-2 rounded-lg"} />
      <GetBusCompanies />
    </div>
  )
}

export default BusCompanies
