import React, { useEffect, useState , useContext } from 'react'
import { GetFdrTodayDate } from '../../Utils/Requests'
import Cookies from 'js-cookie'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Container from './fdrTrips/Container';
import TripIdFromFdrContext from '../../hooks/TripIdFromFdr';
import PlacesCount from '../../hooks/SeatesChoosedContext';


function TableFdrToday() {

  const { setTripIdFromFdr  } = useContext(TripIdFromFdrContext)
  const { setPlacesChoosen } = useContext(PlacesCount)

  const token = Cookies.get('token')

  const [data, setData] = useState([])
  const [rowSelected, setRowSelected] = useState({})

  useEffect(() => {
    try {
      GetFdrTodayDate(token).then((data) => {
        setData(data)
      }).catch((err) => {
        setData([])
      })
    } catch (error) {
      setData([])
    }
  }, [])

  // for model : 
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1800,
    height: 750,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
      setOpen(false)
      setTripIdFromFdr('')
      setPlacesChoosen([])
  }

  // function to convert format date  :

  function convertDateTime(timestamp) {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleString().replace(/:\d{2}\s/, ' ');
    return formattedDate;
  }

  return (
    <div className='pr-3 mt-5'>
      <div class="overflow-x-auto rounded-lg border border-gray-200">
        <table class="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
          <thead class="ltr:text-left rtl:text-right">
            <tr>
              <th class="whitespace-nowrap px-4 py-4 font-medium text-gray-900">
                ID
              </th>
              <th class="whitespace-nowrap px-4 py-4 font-medium text-gray-900">
                Bus Station Name
              </th>
              <th class="whitespace-nowrap px-4 py-4 font-medium text-gray-900">
                Date / Time
              </th>
              
              <th class="whitespace-nowrap px-4 py-4 font-medium text-gray-900">
                Tickets Number
              </th>
              <th class="whitespace-nowrap px-4 py-4 font-medium text-gray-900">
                Total Price
              </th>
              <th class="whitespace-nowrap px-4 py-4 font-medium text-gray-900">
                Status
              </th>
              <th class="whitespace-nowrap px-4 py-4 font-medium text-gray-900">
                Choosen Itinerary
              </th>
              <th class="whitespace-nowrap px-4 py-4 font-medium text-gray-900">

              </th>
            </tr>
          </thead>

          <tbody class="divide-y divide-gray-200">
            {data.map((fdr) => (
              <tr>
                <td class="whitespace-nowrap px-4 py-4 font-medium  text-center text-gray-900">{fdr.fdr_id}</td>
                <td class="whitespace-nowrap px-4 py-4 text-center text-gray-700">{fdr.bus_station_name}</td>
                <td class="whitespace-nowrap px-4 py-4 text-center text-gray-700">{convertDateTime(fdr.date_time)}</td>
          <td class="whitespace-nowrap px-4 py-4 text-center text-gray-700 font-bold">{fdr.ticket_id_list.length}</td>
                <td class="whitespace-nowrap px-4 py-4 text-center text-gray-700">{fdr.total_price}</td>
                <td class={`whitespace-nowrap px-4 py-4 text-center font-bold text-gray-700 ${fdr.status ? 'text-green-500' : 'text-red-500'}`}>{fdr.status ? 'True' : 'False'}</td>
                <td class="whitespace-nowrap px-4 py-4 text-center text-gray-700">{fdr.chosen_itinerary_data?.map((d) => (<span className='bg-red-300 mx-2 px-2 py-1 font-bold rounded-md text-black'>{d.arrival_city}</span>))}</td>
                {fdr.status === false ? <button  className='bg-red-300 text-white px-4 py-2 mt-2  rounded-md text-center cursor-not-allowed'>Buy Ticket</button> : <button onClick={() => { handleOpen(); setRowSelected(fdr) }} className='bg-red-500 text-white px-4 py-2 mt-2  rounded-md text-center'>Buy Ticket</button>}
              </tr>
            ))}
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Container rowSelected={rowSelected} />
              </Box>
            </Modal>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default TableFdrToday
