import { Outlet } from "react-router-dom"
import SideBarAdvanced from "../components/CashierAdvanced/SideBarAdvanced";


function LayoutDashCashierAdvanced() {
    return (
      <div className="flex bg-gray-50">
        <SideBarAdvanced />
            <div
          className=" w-full min-h-screen"
          style={{ marginLeft: "17rem", paddingTop: "2rem" }}
        >
          <Outlet />
        </div>
      </div>
    );
  };

export default LayoutDashCashierAdvanced;
