import React from 'react'

function FormWrapper({Children , title}) {
    return (
        <>
            <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
                <div class="mx-auto max-w-lg">
                    <h1 class="text-center  font-bold text-4xl">{title}</h1>
                    <form action="" class="mb-0 mt-6 space-y-4  sm:p-6 lg:p-8">
                        {Children}
                    </form>
                </div>
            </div>
        </>
    )
}

export default FormWrapper
