import React from 'react'

function Logout() {

  return (
    <div  class=" relative cursor-pointer flex flex-row items-center h-11  focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-red-500 pr-6">
    <span class="inline-flex justify-center items-center px-6">
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.04616 4.98494C6.29158 2.13494 7.75616 0.971191 10.9624 0.971191H11.0653C14.6041 0.971191 16.0212 2.38828 16.0212 5.92703V11.0887C16.0212 14.6274 14.6041 16.0445 11.0653 16.0445H10.9624C7.77991 16.0445 6.31533 14.8966 6.05408 12.0941M10.8753 8.49994H1.86616M3.63158 5.84786L0.979492 8.49994L3.63158 11.152" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /></svg>
    </span>
    <span class="ml-2 text-sm tracking-wide truncate" >Logout</span>
</div>
  )
}

export default Logout
