import React, { useEffect, useState } from "react";
import BarAdd from "../../../../components/Admin/BarAdd";
import FormWrapper from "../../../../components/Admin/FormWrapper";
import CustomLabel from "../../../../components/CustomLabel";
import CustomInput from "../../../../components/CustomInput";
import CustomButton from "../../../../components/CustomButton";
import {
  GetAllCompanies,
  addBusesToAgreement,
  deleteBusesFromAgreement,
  getAgreementById,
  getAllBuses,
  updateAgreement,
} from "../../../../Utils/Requests";
import Cookies from "js-cookie";
import { useMessage } from "../../../../hooks/MessageContext";
import { useNavigate, useParams } from "react-router-dom";
import { Autocomplete, TextField } from "@mui/material";
import LoadingSpinner from "../../../../components/LoadingSpinner";

function UpdateAgreement() {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);

  const [agreement_uid, setAgreement_uid] = useState("");
  const [expiration_date, setExpiration_date] = useState("");
  const [bus_company_id, setBus_company_id] = useState(null);
  const [bus_id, setBus_id] = useState([]);
  const [newBus_id, setNewBus_id] = useState([]);
  const [buses, setBuses] = useState([]);
  const [bus_companies, setBus_companies] = useState([]);

  const token = Cookies.get("token");
  const { setMessage } = useMessage();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const agreement = await getAgreementById(id, token);
      const buses = await getAllBuses(token);
      const bus_companies = await GetAllCompanies(token);

      setAgreement_uid(agreement.agreement_uid);
      setExpiration_date(agreement.expiration_date.slice(0, 10));
      setBus_company_id(agreement.bus_company_id);
      setBus_id(agreement.buses_id);
      setBus_companies(bus_companies);

      const busesIds = buses.map((bus) => ({
        title: bus.license_plate,
        id: bus.bus_id,
      }));
      setBuses(busesIds);

      setIsLoading(false);
    })();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const diff = (a, b) => a.filter((x) => b.indexOf(x) < 0);

    const data = {
      agreement_uid,
      expiration_date,
      bus_company_id,
    };

    try {
      await updateAgreement(id, data, token);
      
        const busesToDelete = diff(bus_id, newBus_id);
        const busesToAdd = diff(newBus_id, bus_id);
        await deleteBusesFromAgreement(id, busesToDelete, token);
        await addBusesToAgreement(id, busesToAdd, token);
      
      setMessage("Agreement created successfully");
      navigate("/agreements");
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) return <LoadingSpinner open />;

  return (
    <>
      <BarAdd title="Update Agreement" />
      <FormWrapper
        title="Update Agreement"
        Children={
          <>
            <CustomLabel label="AGREEMENT UID" />
            <CustomInput
              id="AGREEMENT UID"
              name="AGREEMENT UID"
              type="text"
              placeholder="Agreement UID"
              value={agreement_uid}
              onChange={(e) => setAgreement_uid(e.target.value)}
            />
            <CustomLabel label="Expiration Date" />
            <CustomInput
              id="Expiration Date"
              name="Expiration Date"
              type="date"
              placeholder="Expiration Date"
              value={expiration_date}
              onChange={(e) => setExpiration_date(e.target.value)}
            />
            <CustomLabel label="Bus Company" />
            <Autocomplete
              options={bus_companies}
              getOptionLabel={(option) => option.name}
              defaultValue={
                bus_companies[
                  bus_companies.findIndex(
                    (company) => company.bus_company_id === bus_company_id
                  )
                ]
              }
              onChange={(e, value) =>
                setBus_company_id(value?.bus_company_id || [])
              }
              filterSelectedOptions
              renderInput={(params) => (
                <TextField {...params} label="select bus company" />
              )}
            />
            <CustomLabel label="Bus" />
            <Autocomplete
              multiple
              options={buses}
              getOptionLabel={(option) => option.title}
              defaultValue={bus_id.map(
                (busId) => buses[buses.findIndex((bus) => bus.id === busId)]
              )}
              onChange={(e, value) =>
                setNewBus_id(value.map((bus) => bus.id) || [])
              }
              filterSelectedOptions
              renderInput={(params) => (
                <TextField {...params} label="select bus" />
              )}
            />
            <CustomButton
              type="submit"
              content="Update"
              onClick={handleSubmit}
            />
          </>
        }
      />
    </>
  );
}

export default UpdateAgreement;
