import { Outlet , Navigate } from "react-router-dom";
import Cookies from 'js-cookie'



const IsLoggedIn = () => {
    const token = Cookies.get('token')
    return (
        token ? <Outlet /> : <Navigate to="/" />
    )
}

export default IsLoggedIn