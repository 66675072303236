import React from 'react'
import Logout from '../Admin/Logout'
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import Logo from '../../Assets/Images/logo.png'
import TripIdCashierAdvancedContext from '../../hooks/TripIdCashierAdvanced'
import TripsLists from '../../hooks/TripsCashierAdvanced'
import { useContext } from 'react'

function SideBarAdvanced() {


  const { setListOfTrips } = useContext(TripsLists)
  const { setTripIdCashierAdvanced , setItinerary } = useContext(TripIdCashierAdvancedContext)

  function ClearAll(){
    setListOfTrips([])
    setTripIdCashierAdvanced('')
    setItinerary([])
  }

  const isActive = () => {
    
  }  

  const navigate = useNavigate()

  const logout = () => {
    Cookies.remove('token')
    Cookies.remove('role')
    navigate('/')
  }

  return (
    <>
    <div class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-gray-50 text-gray-800 ">
                <div class=" fixed  flex flex-col top-0 left-0 w-64 bg-white h-full border-r">
                    <div>
                        <img src={Logo} alt="logo" style={{ width: '10rem', marginTop: '2rem', marginLeft: '2rem',marginBottom: '5rem' }} />
                    </div>

                   
                    <div class="overflow-y-auto overflow-x-hidden flex-grow">
                        <ul class="flex flex-col py-4 space-y-1">
                            <Link to="/searchtripsAdvancedCashier" isActive={isActive}>
                                <a onClick={ClearAll} href="#" class="relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-red-500 pr-6">
                                    <span class="inline-flex justify-center items-center px-6">
                                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_226_186)">
                                                <path d="M9.50025 14.25V11.875M7.97233 2.23246L2.48608 6.62621C1.86858 7.11705 1.47275 8.15413 1.60733 8.92996L2.66025 15.2316C2.85025 16.3558 3.92692 17.2662 5.06692 17.2662H13.9336C15.0657 17.2662 16.1502 16.3479 16.3402 15.2316L17.3932 8.92996C17.5198 8.15413 17.124 7.11705 16.5144 6.62621L11.0282 2.24038C10.1811 1.55955 8.8115 1.55955 7.97233 2.23246Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_226_186">
                                                    <rect width="19" height="19" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                    <span class="ml-2 text-sm tracking-wide truncate">Tickets</span>
                                </a>
                            </Link>
                            <Link to="/FeuilleDeRouteDateToday">
                                <a onClick={ClearAll} href="#" class="relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-red-500 pr-6">
                                    <span class="inline-flex justify-center items-center px-6">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" y="0.5" width="21" height="21" rx="5.5" stroke="black" />
                                            <g clip-path="url(#clip0_226_168)">
                                                <path d="M14.1602 17.4507H7.83856C6.74195 17.4507 5.83887 16.554 5.83887 15.451V6.54913C5.83887 5.45252 6.7355 4.54944 7.83856 4.54944H14.1602C15.2568 4.54944 16.1599 5.44607 16.1599 6.54913V15.451C16.1599 16.554 15.2632 17.4507 14.1602 17.4507Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M15.1923 11.6451H6.80646C6.27106 11.6451 5.83887 11.2129 5.83887 10.6775V9.38739C5.83887 8.85199 6.27106 8.4198 6.80646 8.4198H15.1923C15.7277 8.4198 16.1599 8.85199 16.1599 9.38739V10.6775C16.1599 11.2129 15.7277 11.6451 15.1923 11.6451Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M8.73828 14.6769H8.74344M13.2537 14.6769H13.2595" stroke="black" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M9.38672 6.48462H12.612" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_226_168">
                                                    <rect width="15.4815" height="15.4815" fill="white" transform="translate(3.25879 3.25928)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                    <span class="ml-2 text-sm tracking-wide truncate">Feuille De Route</span>
                                </a>
                            </Link>
                            <li onClick={logout}>
                            <Logout />
                            </li>
                        </ul>
                    </div>
                </div>
            </div >
    </>
  )
}

export default SideBarAdvanced
