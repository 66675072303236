import { createContext , useContext , useState } from "react";

const ChoosenItinerary = createContext()


const ItineraryProvider = ({ children }) => {
    const [dataItinerary , setDataItinerary] = useState([])
    return (
        <ChoosenItinerary.Provider value={{dataItinerary , setDataItinerary}}>
            {children}
        </ChoosenItinerary.Provider>
    )
}

export default ChoosenItinerary;
export {ItineraryProvider}