import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { GetAllRoutes, getAllowedRoutes } from "../../../../Utils/Requests";
import GetAllowedRoutesRow from "./GetAllowedRoutesRow";
import { useMessage } from "../../../../hooks/MessageContext";
import { Toaster, toast } from "react-hot-toast";

function GetAllowedRoutes() {
  const token = Cookies.get("token");
  const [allowedRoutes, setAllowedRoutes] = useState([]);
  const [itinerary, setItinerary] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const allowedRoutes_data = await getAllowedRoutes(token);
        setAllowedRoutes(allowedRoutes_data);

        const routes = await GetAllRoutes(token);
        const itinerary = routes.map((route) => ({
          route: `${route.departure_city} -> ${route.arrival_city}`,
          id: route.route_id,
        }));
        setItinerary(itinerary);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const { message, setMessage } = useMessage();

  if (message) {
    toast.success(message);
    setMessage(null);
  }

  const resetData = (id) => {
    let res = allowedRoutes.filter((route) => route.allowed_route_id !== id);
    setAllowedRoutes(res);
  };

  const col = [
    "Agreement ID",
    "Autorisation ID",
    "Bus Company",
    "Status",
    "Bus License Plate",
    "Allowed Departure Time",
    "Departure City",
    "Arrival City",
    "Category",
    "Type",
    "Quai",
    "Km",
    "Allowed Itinerary",
    "Chosen Itinerary",
  ];
  return (
    <>
      <div>
        <Toaster position="bottom-right" reverseOrder={false} />
      </div>
      {/* search bar */}
      <form className="mt-3 pr-4 ">
        <div className="my-2 flex justify-center items-center gap-4">
          <div>
            <select
              id="countries"
              class="bg-gray-50 border p-4 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
            >
              <option disabled selected>
                Choose a Option
              </option>
              {col.map((el) => (
                <option value="Donnateur">{el}</option>
              ))}
            </select>
          </div>
          <div>
            <label
              htmlFor="default-search"
              className="mb-2 text-sm font-medium text-gray-900 sr-only"
            >
              Search
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Search for Bus"
                required
              />
              <button
                type="submit"
                className="text-white absolute right-2.5 bottom-2.5 bg-red-500 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </form>
      {/* end of search bar  */}
      <div class="relative overflow-x-auto mt-4">
        <table class="w-full text-sm text-left text-gray-500">
          <thead class="text-xs  text-gray-700 uppercase">
            <tr>
              {col.map((el) => (
                <th scope="col" class="px-6 py-3 ">
                  {el}
                </th>
              ))}
              <th scope="col" class="px-6 py-3"></th>
              <th scope="col" class="px-6 py-3"></th>
            </tr>
          </thead>
          <tbody>
            {allowedRoutes.map((el) => (
              <GetAllowedRoutesRow
                {...el}
                allItinerary={itinerary}
                resetData={resetData}
                setMessage={setMessage}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default GetAllowedRoutes;
