import "./App.css";
import Login from "./pages/Login";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from "./components/NotFound";
import LayoutAuth from "./Layouts/LayoutAuth";
import SearchTrips from "./pages/SearchTrips";
import ResultPage from "./pages/ResultPage";
import IsLoggedIn from "./Utils/isLoggedIn";
import { TripProvider } from "./hooks/TripIdContext";
import { MessageProvider } from "./hooks/MessageContext";
import { GareProvider } from "./hooks/GareNameContext";
import { ItineraryProvider } from "./hooks/ChoosenItinerary"
import { TripsListsProvider } from "./hooks/TripsCashierAdvanced";
import { TripIdCashierAdvancedProvider } from './hooks/TripIdCashierAdvanced'
import { PlaceProvider } from './hooks/SeatesChoosedContext'
import { TripFromFdrProvider } from "./hooks/TripIdFromFdr";
import { PlaceAdProvider } from './hooks/PlaceContext'
import ProtectedRouteAdmin from "./components/ProtectedRouteAdmin";
import ProtectedRouteCashier from "./components/ProtectedRouteCashier";
import ProtectedRouteCashierAdvanced from "./components/ProtectedRouteCashierAdvanced";
// Admin component :
import LayoutDash from "./Layouts/LayoutDash";
import LayoutDashCashierAdvanced from "./Layouts/LayoutDashCashierAdvanced";
// Pages :

import Home from './pages/SuperAdmin/Home'
import BusCompanies from './pages/SuperAdmin/BusCompanies'
import Bus from './pages/SuperAdmin/Bus'
import BusStation from './pages/SuperAdmin/BusStations'
import Agreement from './pages/SuperAdmin/Agreements'
import AllowedRoutes from './pages/SuperAdmin/AllowedRoutes'
import RoutesPage from './pages/SuperAdmin/RoutesPage'
import Users from './pages/SuperAdmin/Users'
import BusCompaniesName from './pages/SuperAdmin/Data/Add/BusCompaniesName';
import AddBus from './pages/SuperAdmin/Data/Add/AddBus'
import AddBusStation from './pages/SuperAdmin/Data/Add/AddBusStation'
import AddAgreement from './pages/SuperAdmin/Data/Add/AddAgreement'
import AddAllowedRoutes from './pages/SuperAdmin/Data/Add/AddAllowedRoutes'
import AddRoutes from './pages/SuperAdmin/Data/Add/AddRoutes'
import AddUsers from './pages/SuperAdmin/Data/Add/AddUsers';
import UpdateCompaniesName from './pages/SuperAdmin/Data/Put/UpdateCompaniesName';
import UpdateRoute from "./pages/SuperAdmin/Data/Put/RouteUpdate";
import PutBus from "./pages/SuperAdmin/Data/Put/PutBus";
import UpdateAgreement from "./pages/SuperAdmin/Data/Put/UpdateAgreement";
import UpdateBusStationComponent from "./pages/SuperAdmin/Data/Put/UpdateBusStationComponent";
import UpdateUser from './pages/SuperAdmin/Data/Put/UpdateUser'
import Fdrs from "./pages/SuperAdmin/Fdrs";
import AddFdr from "./pages/SuperAdmin/Data/Add/AddFdr";
import PutFdr from "./pages/SuperAdmin/Data/Put/PutFdr";
import UpdateAllowedRoute from "./pages/SuperAdmin/Data/Put/UpdateAllowedRoute";
import Trip from "./pages/SuperAdmin/Trip";
import Logs from "./pages/SuperAdmin/Logs";
import Printers from "./pages/SuperAdmin/Printers";
import AddIpUser from "./pages/SuperAdmin/Data/Add/AddIpUser";
import PutIpUser from "./pages/SuperAdmin/Data/Put/PutIpUser";
import Completed from "./components/CashierAdvanced/fdrTrips/Completed";


// advanced Cashier 
import { SearchTripsAdvancedCashier , FeuilleDeRoute   } from './pages/CashierAdvanced'

function App() {
  return (
    <Router>
      <MessageProvider>
        <ItineraryProvider>
        <TripProvider>
        <GareProvider>
          <TripsListsProvider>
            <TripIdCashierAdvancedProvider>
            <PlaceProvider>
              <TripFromFdrProvider>
                <PlaceAdProvider>
          <Routes>
            {/* auth */}
            
            <Route element={<LayoutAuth />}>
              <Route path="/" element={<Login />} />  
            </Route>
            {/* Pages */}
            <Route element={<IsLoggedIn />}>
              
              <Route element={<ProtectedRouteCashier />}>
     <Route path="/searchtrips" element={<SearchTrips />} />
              <Route
                path="/resultatTrip/:city/:datetime/:min_seats"
                element={<ResultPage />}
              />
              </Route>

              {/* Admin */}
              <Route element={<ProtectedRouteAdmin />}>             
              <Route element={<LayoutDash />}>
                <Route path="/home" element={<Home />} />
                <Route path="/buscompanies" element={<BusCompanies />} />
                <Route path="/addbuscompanies" element={<BusCompaniesName />} />
                <Route path="/bus" element={<Bus />} />
                <Route path="/addbus" element={<AddBus />} />
                <Route path="/updateBus/:id" element={<PutBus />} />
                <Route path="/busStation" element={<BusStation />} />
                <Route path="/addbusStation" element={<AddBusStation />} />
                <Route path="/agreements" element={<Agreement />} />
                <Route path="/addagreement" element={<AddAgreement />} />
                <Route path="/allowedroutes" element={<AllowedRoutes />} />
                <Route
                  path="/addAllowedRoutes"
                  element={<AddAllowedRoutes />}
                />
                <Route path="/routespages" element={<RoutesPage />} />
                <Route path="/AddRoutes" element={<AddRoutes />} />
                <Route path="/users" element={<Users />} />
                <Route path="/AddUsers" element={<AddUsers />} />
              </Route>
              {/* Admin */}
              <Route element={<LayoutDash />}>
                <Route path="/home" element={<Home />} />
                <Route path="/buscompanies" element={<BusCompanies />} />
                <Route path="/addbuscompanies" element={<BusCompaniesName />} />
                <Route
                  path="/updateNameCompany/:id/:name"
                  element={<UpdateCompaniesName />}
                />
                <Route path="/bus" element={<Bus />} />
                <Route path="/addbus" element={<AddBus />} />
                <Route path="/busStation" element={<BusStation />} />
                <Route path="/addbusStation" element={<AddBusStation />} />
                <Route path="/updateBusStation/:id" element={<UpdateBusStationComponent />} />
                <Route path="/agreements" element={<Agreement />} />
                <Route path="/addagreement" element={<AddAgreement />} />
                <Route path="/update-agreement/:id" element={<UpdateAgreement />} />
                <Route path="/allowedroutes" element={<AllowedRoutes />} />
                <Route
                  path="/addAllowedRoutes"
                  element={<AddAllowedRoutes />}
                />
                <Route path="/routespages" element={<RoutesPage />} />
                <Route path="/AddRoutes" element={<AddRoutes />} />
                <Route path="/users" element={<Users />} />
                <Route path="/AddUsers" element={<AddUsers />} />
                <Route path="/updateUser/:id" element={<UpdateUser />} />
              </Route>
              {/* Admin */}
                <Route element={<LayoutDash />}>
                <Route path='/home' element={<Home />} />
                <Route path='/buscompanies' element={<BusCompanies />} />
                <Route path='/addbuscompanies' element={<BusCompaniesName />} />
                <Route path='/updateNameCompany/:id/:name' element={<UpdateCompaniesName />} />
                <Route path='/bus' element={<Bus />} />
                <Route path='/addbus' element={<AddBus />} />
                <Route path='/busStation' element={<BusStation />} />
                <Route path='/addbusStation' element={<AddBusStation />} />
                <Route path='/agreements' element={<Agreement />} />
                <Route path='/addagreement' element={<AddAgreement />} />
                <Route path='/allowedroutes' element={<AllowedRoutes />} />
                <Route path='/addAllowedRoutes' element={<AddAllowedRoutes />} />
                <Route path='/update-allowedroutes/:id/' element={<UpdateAllowedRoute />} />
                <Route path='/routespages' element={<RoutesPage />} />
                <Route path='/AddRoutes' element={<AddRoutes />} />
                <Route path="/updateRoute/:id" element={<UpdateRoute />} />
                <Route path='/users' element={<Users />} />
                <Route path='/AddUsers' element={<AddUsers />} />
                <Route path="/FeuilleDeRoute" element={<Fdrs />} />
                <Route path="/AddFdr" element={<AddFdr />} />
                <Route path="/updateFeuilleDeRoute/:id" element={<PutFdr />} />
                <Route path="/trips" element={<Trip />} />
                <Route path="/Logs" element={<Logs />} />
                <Route path="/Printers" element={<Printers />} />
                <Route path="/AddPrinter" element={<AddIpUser />} />
                <Route path="/putipPrinter/:user_ip/:printer_ip" element={<PutIpUser />} />
                </Route>
                </Route>
              </Route>  
              <Route />
              <Route path='*' element={<NotFound />} />     



              {/* Advanced Cashier   */}
              <Route element={<ProtectedRouteCashierAdvanced />}>
                <Route element={<LayoutDashCashierAdvanced />}>
                    <Route path="/searchtripsAdvancedCashier" element={<SearchTripsAdvancedCashier />} />
                    <Route path="/FeuilleDeRouteDateToday" element={<FeuilleDeRoute />} />
                    <Route path="/Completed" element={<Completed />}/>
                </Route>
              </Route>
              {/* end Advanced Cashier  */}
          </Routes>
          </PlaceAdProvider>
          </TripFromFdrProvider>
          </PlaceProvider>
          </TripIdCashierAdvancedProvider>
          </TripsListsProvider>
          </GareProvider>
        </TripProvider>
        </ItineraryProvider>
      </MessageProvider>
    </Router>
  );
}

export default App;
