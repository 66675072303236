  // function to change format data from 2023-05-29T00:00:00 to 2023-05-29 00:00
  export  function ConvertFormatDate(data) {
    const date = new Date(data);
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit'
    }).replace(/\//g, '-');
    const formattedTime = date.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit'
    });
    
    const formattedDateTime = `${formattedDate} ${formattedTime}`;
    return formattedDateTime;
  }