import React, { useEffect, useState } from "react";
import BarAdd from "../../../../components/Admin/BarAdd";
import FormWrapper from "../../../../components/Admin/FormWrapper";
import CustomLabel from "../../../../components/CustomLabel";
import CustomInput from "../../../../components/CustomInput";
import CustomButton from "../../../../components/CustomButton";
import Cookies from "js-cookie";
import {
  GetAllCompanies,
  GetAllRoutes,
  createAllowedRoute,
  getAllAgreements,
  getAllBuses,
  getAllowedRoutes,
} from "../../../../Utils/Requests";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useMessage } from "../../../../hooks/MessageContext";
import { useNavigate } from "react-router";
import LoadingSpinner from "../../../../components/LoadingSpinner";

function AddAllowedRoutes() {
  const token = Cookies.get("token");
  const [agreement_id, setAgreement_id] = useState(null);
  const [autorisation_id, setAutorisation_id] = useState(null);
  const [bus_company_id, setBus_company_id] = useState(null);
  const [status, setStatus] = useState(null);
  const [bus_id, setBus_id] = useState(null);
  const [allowed_departure_time, setAllowed_departure_time] = useState("");
  const [departure_city, setDeparture_city] = useState("");
  const [arrival_city, setArrival_city] = useState("");
  const [category, setCategory] = useState("");
  const [type, setType] = useState("");
  const [quai, setQuai] = useState("");
  const [km, setKm] = useState(null);
  const [allowed_itinerary, setAllowed_itinerary] = useState([]);
  const [chosen_itinerary, setChosen_itinerary] = useState([]);

  const [agreements, setAgreements] = useState([]);
  const [bus_companies, setBus_companies] = useState([]);
  const [buses, setBuses] = useState([]);

  const [routes, setRoutes] = useState([]);
  const [departure_city_list, setDeparture_city_list] = useState([]);
  const [arrival_city_list, setArrival_city_list] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const { setMessage: setSuccessMessage } = useMessage();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const agreements_data = await getAllAgreements(token);
      const bus_company_data = await GetAllCompanies(token);
      const buses_data = await getAllBuses(token);

      const agreements = agreements_data.map((agreement) => ({
        title: agreement.agreement_uid,
        id: agreement.agreement_id,
      }));
      const bus_companies = bus_company_data.map((company) => ({
        title: company.name,
        id: company.bus_company_id,
      }));
      const buses = buses_data.map((bus) => ({
        title: bus.license_plate,
        id: bus.bus_id,
      }));
      setAgreements(agreements);
      setBus_companies(bus_companies);
      setBuses(buses);

      const routes = await GetAllRoutes(token);

      const itinerary = routes.map((route) => ({
        title: `${route.departure_city} -> ${route.arrival_city}`,
        id: route.route_id,
      }));
      const departure_city = [...new Set(routes.map((route) => route.departure_city))];
      const arrival_city = [...new Set(routes.map((route) => route.arrival_city))];
      
      setRoutes(itinerary);
      setDeparture_city_list(departure_city);
      setArrival_city_list(arrival_city);
      setIsLoading(false);
    })();
  }, []);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      agreement_id,
      autorisation_id,
      bus_company_id,
      status,
      bus_id,
      allowed_departure_time,
      departure_city,
      arrival_city,
      category,
      type,
      quai,
      km,
      allowed_itinerary,
      chosen_itinerary,
    };
    
    try {
      await createAllowedRoute(data, token);
      setSuccessMessage("allowed route has been added successfully!");
      navigate("/allowedroutes");
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) return <LoadingSpinner open />;
  
  return (
    <>
      <BarAdd title="Add Allowed Route" />
      <FormWrapper
        title="Add Allowed Route"
        Children={
          <>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <CustomLabel className="mb-2" label="AGREEMENT" />
                <Autocomplete
                  onChange={(event, value) =>
                    value?.id
                      ? setAgreement_id(value.id)
                      : setAgreement_id(null)
                  }
                  options={agreements}
                  getOptionLabel={(option) => option.title}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField {...params} label="Select Agreement" />
                  )}
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <CustomLabel className="mb-2" label="AUTORISATION ID	" />
                <CustomInput
                  placeholder="AUTORISATION ID"
                  type="number"
                  onChange={(e) => setAutorisation_id(+e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <CustomLabel className="mb-2" label="BUS COMPANY ID	" />
                <Autocomplete
                  onChange={(event, value) =>
                    value?.id
                      ? setBus_company_id(value.id)
                      : setBus_company_id(null)
                  }
                  options={bus_companies}
                  getOptionLabel={(option) => option.title}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField {...params} label="Select Bus Company" />
                  )}
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <CustomLabel className="mb-2" label="STATUS" />
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    label="status"
                    onChange={(e) =>
                      e.target.value ? setStatus(true) : setStatus(false)
                    }
                  >
                    <MenuItem value={1}>true</MenuItem>
                    <MenuItem value={0}>false</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <CustomLabel className="mb-2" label="ALLOWED DEPARTURE TIME" />
                <CustomInput
                  type="time"
                  placeholder="ALLOWED DEPARTURE TIME"
                  onChange={(e) => setAllowed_departure_time(e.target.value)}
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <CustomLabel className="mb-2" label="DEPARTURE CITY	" />
                <Autocomplete
                  onChange={(event, value) =>
                    value ? setDeparture_city(value) : setDeparture_city("")
                  }
                  options={departure_city_list}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField {...params} label="Select Departure city" />
                  )}
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <CustomLabel className="mb-2" label="Arrival City" />
                <Autocomplete
                  onChange={(event, value) =>
                    value ? setArrival_city(value) : setArrival_city("")
                  }
                  options={arrival_city_list}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField {...params} label="Select Arrival city" />
                  )}
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <CustomLabel className="mb-2" label="Category" />
                <CustomInput
                  placeholder="Category"
                  onChange={(e) => setCategory(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <CustomLabel className="mb-2" label="TYPE" />
                <CustomInput
                  placeholder="TYPE"
                  onChange={(e) => setType(e.target.value)}
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <CustomLabel className="mb-2" label="QUAI" />
                <CustomInput
                  placeholder="QUAI"
                  onChange={(e) => setQuai(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <CustomLabel className="mb-2" label="BUS" />
                <Autocomplete
                  onChange={(event, value) =>
                    value?.id ? setBus_id(value.id) : setBus_id(null)
                  }
                  options={buses}
                  getOptionLabel={(option) => option.title}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField {...params} label="Select Bus" />
                  )}
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <CustomLabel className="mb-2" label="KM" />
                <CustomInput
                  type="number"
                  placeholder="KM"
                  onChange={(e) => setKm(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <CustomLabel className="mb-2" label="ALLOWED ITINERARY" />
                <Autocomplete
                  multiple
                  options={routes}
                  getOptionLabel={(option) => option?.title}
                  onChange={(e, value) =>
                    setAllowed_itinerary(
                      value.map((itinerary) => itinerary.id) || []
                    )
                  }
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField {...params} label="select itinerary" />
                  )}
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <CustomLabel className="mb-2" label="CHOSEN ITINERARY" />
                <Autocomplete
                  multiple
                  options={routes}
                  getOptionLabel={(option) => option?.title}
                  onChange={(e, value) =>
                    setChosen_itinerary(
                      value.map((itinerary) => itinerary.id) || []
                    )
                  }
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField {...params} label="select itinerary" />
                  )}
                />
              </div>
            </div>
            <CustomButton type="submit" content="Add" onClick={handleSubmit} />
          </>
        }
      />
    </>
  );
}

export default AddAllowedRoutes;
