import { useState , createContext } from "react";


const TripIdFromFdrContext = createContext()

const TripFromFdrProvider = ({ children }) => {

    const [tripIdFromFdr,setTripIdFromFdr] = useState('')
    const [seatsTripsFromFdr,setSeatsTripsFromFdr] = useState('')
    const [itinerary , setItinerary] = useState([])

    return (
        <TripIdFromFdrContext.Provider 
            value={{
                tripIdFromFdr, 
                setTripIdFromFdr,
                seatsTripsFromFdr,
                setSeatsTripsFromFdr,
                itinerary,
                setItinerary
                }}>
            {children}
        </TripIdFromFdrContext.Provider>
    )
}

export default TripIdFromFdrContext;
export { TripFromFdrProvider }