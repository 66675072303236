import React from 'react'
import Logo from '../Assets/Images/logo.png'
import { Link } from 'react-router-dom'
import DropDown from './DropDown'
import FirstProgressBar from './ProgressBar'

function Navbar() {
    return (
        <>
            <header aria-label="Site Header" class="bg-white">
                <div class="mx-auto  px-4 sm:px-6 lg:px-8" style={{ maxWidth: '1824px' }}>
                    <div class="flex h-16 items-center justify-between">
                        <div class="md:flex md:items-center md:gap-12">
                            <Link class="block ">
                                <img src={Logo} alt="logo" style={{ width: '8rem' }} />
                            </Link>
                        </div>

                        {/* link */}
                        <FirstProgressBar />
                        <div class="flex items-center gap-4">
                            {/* dropdown here  */}
                            <DropDown />
                            <div class="block md:hidden">
                                <button
                                    class="rounded bg-gray-100 p-2 text-gray-600 transition hover:text-gray-600/75"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="h-5 w-5"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        stroke-width="2"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M4 6h16M4 12h16M4 18h16"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

        </>
    )
}

export default Navbar
