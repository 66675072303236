import React from 'react'
import BarAdd from '../../../../components/Admin/BarAdd'
import FormWrapper from '../../../../components/Admin/FormWrapper'
import CustomButton from '../../../../components/CustomButton'
import CustomLabel from '../../../../components/CustomLabel'
import CustomInput from '../../../../components/CustomInput'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import { UpdateCompany } from '../../../../Utils/Requests'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import Alert from '../../../../components/Alert'

function UpdateCompaniesName() {

  // Get ID by params : 
  const token = Cookies.get('token')
  const params = useParams()
  const nameCompany = params.name
  const id  = params.id

  const [name,setName] = useState('')
  const [error , setError] = useState(false)
  const [message, setMessage] = useState('')

  const handleNewName = (e) => {
      return setName(e.target.value)
  }

  const nav = useNavigate()
  const UpdateHandler = async (e) => {
        e.preventDefault()
        const data = {
          name,
        }
      try {
        UpdateCompany(token,id,data).then(() => {
          setError(false)
          nav('/buscompanies')
        }).catch(() => {
          setError(true)
          setMessage('Server Error , try Again or please choose Another Name !')
        })
      } catch (error) {
          setError(true)
          setMessage('Server Error , try Again or please choose Another Name !')
      }
  }

  // send Req to get Data :

  return (
    <>
    <BarAdd title="Update Bus Company Name" />
    {error && <Alert className="flex mx-auto p-4 mb-4 mt-7 w-64 text-sm text-red-800 rounded-lg bg-red-100" message={message} />}
    <FormWrapper 
        title="Update Bus Company Name"
        Children={
            <>
            <CustomLabel label="Bus Company Name" />
            <CustomInput
                id="Name Company"   
                name="nameCompany"
                type="text"
                onChange={handleNewName}
                placeholder={nameCompany}
            />
            <CustomButton
                onClick={UpdateHandler}
                type="submit"
                content="Update"
            />
            </>
        }
    />
    </>
  )
}

export default UpdateCompaniesName
