import React from "react";
import { GetAllBusStation , DeleteBusStation } from '../../../../Utils/Requests'
import { useEffect , useState } from "react";
import Cookies from "js-cookie";
import toast , { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";



function GetBusStation() {

  const token = Cookies.get('token')
  const [succes , setSucces ] = useState(false)
  const [data , setData ] = useState([])


  // function for toast in case Succes : 
    function ToastSucces(){
      toast.success(`Bus Station deleted Successfully`, {
        position: 'bottom-right'
      })
    }

    function ToastError(){
      toast.error(`Server Error  u cant delete this Bus Station` , {
        position: 'bottom-right'
      })
    }

  function GetData(){
    try {
      GetAllBusStation(token).then((data) => {
        setData(data)
        setSucces(true)
      }).catch(() => {
        setSucces(false)
      })
    } catch (error) {
        setSucces(false)
    }
  }

  useEffect(() => {
    GetData()
  } , [])

  // Function Delete Bus Station : 
  const deleteBusStation = (id) => {
    try {
      DeleteBusStation(id,token).then(() => {
        let res = data.filter((station) => station.bus_station_id !== id)
        setData(res)
        ToastSucces()
      }).catch(() => {
        ToastError()
      })
    } catch (error) {
        ToastError()
    }
  }


  return (
    <>
      {/* search bar */}
      <form className="mt-3 pr-4 ">
        <div className="my-2 flex justify-center items-center gap-4">
          <div>
            <select
              id="countries"
              class="bg-gray-50 border p-4 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
            >
              <option disabled selected>
                Choose a Option
              </option>
              <option value="Donnateur">Name</option>
              <option value="Donnateur">Location</option>
              <option value="Donnateur">Phone Number</option>
              <option value="Donnateur">Email</option>
            </select>
          </div>
          <div>
            <label
              htmlFor="default-search"
              className="mb-2 text-sm font-medium text-gray-900 sr-only"
            >
              Search
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Search for Bus Station"
                required
              />
              <button
                type="submit"
                className="text-white absolute right-1 bottom-2.5 bg-red-500 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </form>
      {/* end of search bar  */}
      <div class="relative overflow-x-auto mt-4">
        <table class="w-full text-sm text-left text-gray-500">
          <thead class="text-xs  text-gray-700 uppercase">
            <tr>
              <th scope="col" class="px-6 py-3 ">
                Name
              </th>
              <th scope="col" class="px-6 py-3 ">
                Location
              </th>
              <th scope="col" class="px-6 py-3 ">
                Phone Number
              </th>
              <th scope="col" class="px-6 py-3 ">
                Email
              </th>
            </tr>
          </thead>
          {data.map((BusSta) => (
          <tbody>
            <tr class="bg-white border-b">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
              >
                {BusSta.name}
              </th>
              <td class="px-6 py-4">{BusSta.location}</td>
              <td class="px-6 py-4">{BusSta.phone_number}</td>
              <td class="px-6 py-4">{BusSta.email}</td>
              <td class=" py-4">
                <Link to={`/updateBusStation/${BusSta.bus_station_id}`}>
                <button>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.5493 1.09474L6.54511 8.09886C3.61428 11.0371 1.3948 14.6071 0.0566832 18.5355C-0.0071297 18.7213 -0.0172928 18.9213 0.027359 19.1126C0.0720109 19.304 0.16967 19.4788 0.309141 19.6172L0.312802 19.6208C0.409858 19.7186 0.525291 19.7963 0.652461 19.8493C0.779632 19.9023 0.91603 19.9297 1.05381 19.9298C1.16974 19.9297 1.28488 19.9106 1.39463 19.8732C5.32304 18.5351 8.89307 16.3156 11.8313 13.3847L18.8352 6.38067C19.5361 5.67971 19.9299 4.72902 19.9299 3.73772C19.9299 2.74642 19.5361 1.79572 18.8352 1.09476C18.4881 0.747679 18.0761 0.47236 17.6226 0.284521C17.1691 0.0966824 16.6831 1.95361e-06 16.1922 0C15.2009 -3.9454e-06 14.2502 0.393785 13.5493 1.09474ZM10.879 12.4327C8.23166 15.0731 5.04314 17.1078 1.53331 18.3966C2.82207 14.8868 4.85681 11.6982 7.49718 9.05092L11.6696 4.87858L15.0513 8.26035L10.879 12.4327ZM17.8831 5.4286L16.0034 7.30828L12.6216 3.92652L14.5013 2.0468C14.7233 1.82422 14.9869 1.64758 15.2772 1.52698C15.5674 1.40638 15.8786 1.34418 16.193 1.34396C16.5073 1.34373 16.8186 1.40547 17.109 1.52565C17.3994 1.64584 17.6633 1.8221 17.8856 2.04436C18.1078 2.26662 18.2841 2.53051 18.4043 2.82095C18.5245 3.11139 18.5862 3.42267 18.586 3.737C18.5857 4.05132 18.5235 4.36251 18.4029 4.65277C18.2823 4.94303 18.1057 5.20667 17.8831 5.4286Z"
                      fill="#10BB55"
                    />
                  </svg>
                </button>
                </Link>
              </td>
              <td class=" py-4">
                <button onClick={() => {deleteBusStation(BusSta.bus_station_id)}}>
                  <svg
                    width="20"
                    height="25"
                    viewBox="0 0 20 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.66068 23.6669C1.65819 23.9942 1.78563 24.3092 2.01504 24.5427C2.24446 24.7762 2.55712 24.9092 2.88445 24.9125H17.0452C17.3725 24.9092 17.6852 24.7762 17.9146 24.5427C18.144 24.3092 18.2714 23.9942 18.2689 23.6669V7.05859H1.66068V23.6669ZM3.32151 8.71942H16.6081V23.2517H3.32151V8.71942Z"
                      fill="#FF0404"
                    />
                    <path
                      d="M7.05829 10.3801H5.39746V20.7603H7.05829V10.3801Z"
                      fill="#FF0404"
                    />
                    <path
                      d="M10.7956 10.3801H9.13477V20.7603H10.7956V10.3801Z"
                      fill="#FF0404"
                    />
                    <path
                      d="M14.5324 10.3801H12.8716V20.7603H14.5324V10.3801Z"
                      fill="#FF0404"
                    />
                    <path
                      d="M13.7018 3.73686V1.24562C13.7018 0.547139 13.2094 0 12.5808 0H7.34916C6.72054 0 6.2281 0.547139 6.2281 1.24562V3.73686H0V5.39769H19.9299V3.73686H13.7018ZM7.88893 1.66083H12.041V3.73686H7.88893V1.66083Z"
                      fill="#FF0404"
                    />
                  </svg>
                </button>
              </td>
            </tr>
          </tbody>
          ))}
        </table>
      </div>
      <Toaster />
    </>
  );
}

export default GetBusStation;
