import React , { useEffect , useState } from 'react'
import BarAdd from '../../../../components/Admin/BarAdd'
import FormWrapper from '../../../../components/Admin/FormWrapper'
import CustomButton from '../../../../components/CustomButton'
import CustomLabel from '../../../../components/CustomLabel'
import CustomInput from '../../../../components/CustomInput'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import Alert from '../../../../components/Alert'
import { AddTripFromFdr, CreateFdr , getAllowedRoutes } from '../../../../Utils/Requests'

function AddFdr() { 



    // get the current date of today : 
    const navigate = useNavigate()
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const fullDate = `${year}-${month}-${day}`

    const token = Cookies.get('token')
    const [data,setData] = useState([])
    const [error,setError] = useState(false)
    const [message,setMessage] = useState('')
   // states to handle : 
    const [allowed_route_id  , setId] = useState(null)
    const [departure_date  , setDate] = useState(fullDate)

    useEffect(() => {
        try {
            getAllowedRoutes(token).then((data) => {
                setData(data)
                setError(false)
            }).catch(() =>  {
                setError(true)
            })
        } catch (error) {
                setError(true)
        }
    }, [])

    //  Function to get ID instead of License Plate
    const idByName = data.reduce((acc, obj) => {
        acc[obj.bus_license_plate] = obj.allowed_route_id;
        return acc;
      }, {});
      function getIdByName(name) {
        return idByName[name];
      }


      // Handle Add FDR : 
      const handleSubmit = async (e) => {
        e.preventDefault()
        const data = {
          allowed_route_id,
          departure_date
        }
        if(Object.values(data).some((field) => field === " " || field === null || field === undefined)){
          setError(true)
          setMessage('please fill all field')
          return
        }
        try {
          const fdr = await CreateFdr(token,allowed_route_id,departure_date);
          await AddTripFromFdr(fdr.fdr_id, token);
          navigate('/FeuilleDeRoute');
          setError(false);
        } catch (error) {
          setError(true)
          setMessage('Error Server , try Again please')
        }
      }
  return (
    <>
      <BarAdd title="Add Feuille De Route" />
      {error && <Alert message={message} className="flex mx-auto p-4 mb-4 mt-7 w-64 text-sm text-red-800 rounded-lg bg-red-100" />}
      <FormWrapper 
      title="Add Feuille De Route"
      Children={
        <>
        <CustomLabel label="Bus Company || License Place" />
        <select onChange={(e) => {setId(getIdByName(e.target.value))}} className="block  text-black appearance-none w-full bg-white border  hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
             <option selected disabled>Select A Bus Company || License Plate</option>
             {data.map((dat) => (
                <option value={dat.bus_license_plate}>
                  {dat.bus_company_name} - {dat.bus_license_plate}
                </option>
                ))}
              </select>
        <CustomLabel label="Date" />
        <CustomInput onChange={(e) => {setDate(e.target.value)}} type="date" />
        <CustomButton 
        onClick={handleSubmit}
        type="submit"
        content="Add"
        />
        </>
      }
      />
    </>
  )
}

export default AddFdr
