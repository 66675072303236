import React from 'react'
import { SearchBarCashierAdvanced , TripsListsCashierA , TicketAdvancedCashier } from '../../components/CashierAdvanced'
import BarAdd from '../../components/Admin/BarAdd'

function SearchTripsAdvancedCashier() {


  

  return (
    <div>
      <BarAdd title="Tickets - Search Trips" />
      <SearchBarCashierAdvanced />
      <div className=' flex flex-row justify-center items-center px-2  mt-3'>
        <div className='w-4/6 border rounded-md   h-[41rem] bg-white shadow-lg' >
          <TripsListsCashierA />
        </div>
        <div  className='w-2/6 border ml-4 rounded-md shadow-lg h-[41rem] bg-white'>
          <TicketAdvancedCashier />
        </div>
    </div>
    </div>
  )
}

export default SearchTripsAdvancedCashier
