import React from "react";

function CustomInput(props) {
  return (
    <input
      id={props.id}
      style={props.style}
      name={props.name}
      onChange={props.onChange}
      type={props.type}
      placeholder={props.placeholder}
      value={props?.value}
      min={props.min}
      max={props.max}
      disabled={props.disabled}
      className=" w-full mt-2 block shadow-xlplaceholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
    />
  );
}

export default CustomInput;
