import React, { useState, useEffect , useContext } from "react";
import './seatsReservation.css'
import { GetSeatsByTripId } from "../../../Utils/Requests";
import Cookies from "js-cookie";
import Seat from "./seat";
import Loader from '../Loader'
import PlacesCount from "../../../hooks/SeatesChoosedContext";


const CarSeats = (props) => {

  const token = Cookies.get('token')
  const fdrId = props.fdrId
  const place = props.place


  const { placesChoosen , setPlacesChoosen } = useContext(PlacesCount)

  const [row1,setRow1] = useState([])   
  const [row2,setRow2] = useState([])
  const [row3,setRow3] = useState([])
  const [row4,setRow4] = useState([])
  const [row5,setRow5] = useState([])
  const [isLoading,setIsLoading] = useState(false)
  const [PlaceSelection,setPlaceSelection] = useState([])
  const [place_selection,setplace_selection] = useState(null)

  const [refreshme,setrefreshme] = useState(false)
  

  useEffect(()=>{
  
    if(place_selection != null){
      if (PlaceSelection.includes(place_selection)) {
        PlaceSelection[PlaceSelection.indexOf(place_selection)] = null
        setPlaceSelection(PlaceSelection)
      } else {
        if (PlaceSelection.includes(null)) {
           PlaceSelection[PlaceSelection.indexOf(null)] = place_selection;
          setPlaceSelection(PlaceSelection)
        } else {
          // last 
          let a = PlaceSelection[place - 3];
          PlaceSelection[place - 3] = place_selection
          setPlaceSelection(PlaceSelection)
        }
      }
      setPlacesChoosen(PlaceSelection)
      setrefreshme(!refreshme)
    }
  },[place_selection])
  useEffect(() => {
    try {
      GetSeatsByTripId(token, fdrId).then((data) => {
        console.log(data);
        setIsLoading(true)
        setTimeout(() => {
          setRow1(data[0])
          setRow2(data[1])
          setRow3(data[2])
          setRow4(data[3])
          setRow5(data[4])
          setIsLoading(false)
        },1000)
      }).catch((err) => {
        console.log(err);
      })
    } catch (error) {
        console.log(error);
    }
  }, [fdrId])

  useEffect(()=>{
 
    let TailleArray = []
    for (let i = 0; i < place; i++) {
      if(placesChoosen[i] != null){
        TailleArray.push(placesChoosen[i]);

      }else{      TailleArray.push(null);
      }
    }
    setPlaceSelection(TailleArray)
  },[])



  return (
    <div className="">
    <p className="flex flex-row ml-7 font-light my-2">  Places Selected : {placesChoosen.map((e) => ( <h1 className="text-red-600 font-bold text-1xl px-3 border-1 border-red">{e}</h1>))}</p>
    <div className="flex flex-row justify-evenly p-4">
      {isLoading && <Loader />}
      <div className="flex flex-row gap-3">
        <div className="flex flex-col gap-3">
          {row1.map((r1) => <Seat setplace_selection={setplace_selection} PlaceSelection={PlaceSelection}  place={place}  refreshme={refreshme}  booked={r1.is_booked} number={r1.number} />)}
        </div>
        <div>
        </div>
        <div className="flex flex-col gap-3">
          {row2.map((r2) => <Seat setplace_selection={setplace_selection} PlaceSelection={PlaceSelection}  place={place} refreshme={refreshme} booked={r2.is_booked} number={r2.number} />)}
        </div>
      </div>
      {/* here  Number 59 */}
      {row3.map((r3) => ( <Seat setplace_selection={setplace_selection} PlaceSelection={PlaceSelection}  place={place} refreshme={refreshme} booked={r3.is_booked} number={r3.number} />))}
      {/* end */} 
      <div className="flex flex-row gap-3">
        <div className="flex flex-col gap-3">
        {row4.map((r3) => <Seat setplace_selection={setplace_selection} PlaceSelection={PlaceSelection} place={place} refreshme={refreshme} booked={r3.is_booked} number={r3.number} />)}
        </div>
        <div className="flex flex-col gap-3">
        {row5.map((r4) => (<Seat setplace_selection={setplace_selection} PlaceSelection={PlaceSelection} place={place} refreshme={refreshme} booked={r4.is_booked} number={r4.number} />))}
        </div>
      </div>
    </div>
      </div>
  )
}
export default CarSeats;