import React from 'react'
import Navbar from '../components/Navbar'
import Container from "../components/Container"
import { Link } from 'react-router-dom'

function ResultPage() {
  return (
    <div>
      <Navbar />
      <div style={{ margin: '2rem 5rem' }}>
        <button className='bg-red-500 px-6 py-2 rounded-lg'>
          <Link to="/searchtrips" className='text-white font-bold'>Retour</Link>
        </button>
        <Container />
      </div>
    </div>
  )
}

export default ResultPage
