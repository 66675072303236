import React, { useState } from 'react'
import BarAdd from '../../../../components/Admin/BarAdd'
import FormWrapper from '../../../../components/Admin/FormWrapper'
import CustomLabel from '../../../../components/CustomLabel'
import CustomInput from '../../../../components/CustomInput'
import CustomButton from '../../../../components/CustomButton'
import { CreateBusStation } from '../../../../Utils/Requests'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import Alert from '../../../../components/Alert'

function AddBusStation() {

  const navigate = useNavigate()
  const token = Cookies.get('token')

  const [name , setName] = useState('')
  const [location , setLocation] = useState('')
  const [phone_number,setPhoneNumber] = useState('')
  const [email , setEmail] = useState('')
  const [error , setError] = useState(false)
  const [message , setMessage] = useState('')

  const handleName = (e) => {
    return setName(e.target.value)
  }
  const handleLocation = (e) => {
    return setLocation(e.target.value)
  }
  const handlePhoneNumber = (e) => {
    return setPhoneNumber(e.target.value)
  }
  const handleEmail = (e) => {
    return setEmail(e.target.value)
  }

  const AddHandler = async (e) => {
    e.preventDefault()
    const data = {
      name,
      location,
      phone_number,
      email
    }
    try {
      CreateBusStation(token,data).then((data) => {
        setError(false)
        navigate('/busStation')
      }).catch(() => {
        setError(true)
        setMessage('Server Error , Try Again or try another Station Information')
      })
    } catch (error) {
        setError(true)
        setMessage('Server Error , Try Again or try another Station Information')
    }
  }

  return (
    <>
    <BarAdd title="Add Bus Station" />
    {error && <Alert className="flex mx-auto p-4 mb-4 mt-7 w-64 text-sm text-red-800 rounded-lg bg-red-100" message={message} />}
    <FormWrapper
        title="Add Bus Station"
        Children={
            <>
            <CustomLabel label="Name Station" />
            <CustomInput
            id="Name"
            onChange={handleName}
            name="Name"
            type="text"
            placeholder="Name"
            />
             <CustomLabel label="Location" />
            <CustomInput
            id="Location"
            onChange={handleLocation}   
            name="Location"
            type="text"
            placeholder="Location"
            />
             <CustomLabel label="Phone Number" />
            <CustomInput
            id="Phone Number"
            onChange={handlePhoneNumber} 
            name="Phone Number"
            type="Phone Number"
            placeholder="Phone Number"
            />
            <CustomLabel label="Email" />
            <CustomInput
            id="Email"
            onChange={handleEmail}   
            name="Email"
            type="text"
            placeholder="Email"
            />
            <CustomButton
            onClick={AddHandler}
            type="submit" 
            content="Add"
            />
            </>
        }
     />
    </>
  )
}

export default AddBusStation
