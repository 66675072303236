import React , {useContext} from 'react'
import GareName from '../hooks/GareNameContext'


function HeroSection() {
    const { nameGare } = useContext(GareName)
    return (
        <div style={{ marginTop: '120px' }}>
            <h1 className='text-center text-white font-bold' style={{ fontSize: '50px' }}>Notre système de réservation en ligne pour la Gare Routière de {nameGare} vous <br />
                permet de gérer  facilement les réservations de billets de transport <br />
                en commun pour nos clients.
            </h1>
            <p className='text-center  mt-6 text-white font-semibold' style={{fontSize: '30px'}}>Utilisez notre barre de recherche pour trouver rapidement des itinéraires et <br />
                réserver des billets pour vos clients.
            </p>
        </div>
    )
}
export default HeroSection
