import { createContext , useState } from "react";


const PlaceAd = createContext()

const PlaceAdProvider = ({ children }) => {
    const [place,setPlace] = useState(1)

    return (
        <PlaceAd.Provider value={{ place , setPlace }}>
            {children}
        </PlaceAd.Provider>
    )
}

export default PlaceAd;
export { PlaceAdProvider }

