import React from 'react'
import FormLogin from '../components/FormLogin'

function Login() {
    return (
       <FormLogin />
    )
}

export default Login
