import { useState , createContext, useContext } from "react";


const TripIdCashierAdvancedContext = createContext();

const TripIdCashierAdvancedProvider = ({ children }) => {
    const [tripIdCashierAdvanced , setTripIdCashierAdvanced] = useState('')
    const [itinerary,setItinerary] = useState([])
    const [seats,setSeats] = useState('')

    return (
        <TripIdCashierAdvancedContext.Provider value={{tripIdCashierAdvanced,setTripIdCashierAdvanced,itinerary,setItinerary,seats,setSeats}}>
            {children}
        </TripIdCashierAdvancedContext.Provider>
    )
}

export default TripIdCashierAdvancedContext;
export { TripIdCashierAdvancedProvider };