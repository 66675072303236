import React, { useEffect, useState , useContext} from 'react'
import PlacesCount from '../../../hooks/SeatesChoosedContext'

function Seat(props) {


  const { placesChoosen , setPlacesChoosen } = useContext(PlacesCount)



  const Place = props.place
   
  const classMap = {
    '00': 'border-2 px-2 py-1 text-center text-white border-white bg-white hover:bg-white hover:text-white invisible',
    '59': 'border-2 px-2 py-1 self-end  text-center text-white border-white rounded-lg'
  }

  let dynamicClass = classMap[props.number] || 'border-2  px-2 py-1 text-center text-white border-white rounded-lg'

  // props.booked

  if (props.booked) {
    dynamicClass += ' cursor-not-allowed bg-gray-400';
  } else {
    dynamicClass += ' cursor-pointer bg-green-600';
  }

  const [numbers, setNumbers] = useState([]);
  const [ischecked,setischecked] = useState(false)
 
  useEffect(()=>{
    if (props.PlaceSelection.includes(props.number) === true) {
      setischecked(true)
    }else{
      setischecked(false)
    }

  },[props?.refreshme])
  const HandleClick = (numbre) => {
    if (props.booked !== true) {
      props.setplace_selection(numbre)
     }
  };

  return (
    <div  onClick={() =>  HandleClick(props.number)} className={ischecked ? `my-class ${dynamicClass} bg-red-500` : `my-class ${dynamicClass}`} >{props.number}</div>
  )
}
// {}
export default Seat;
