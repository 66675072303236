
import {Outlet, Navigate} from 'react-router-dom'
import * as React from 'react';
import Cookies from 'js-cookie';

const ProtectedRouteAdmin = () => {
    

let role= Cookies.get("role")
return(
    role === 'superadmin' ? <Outlet/> : <Navigate to="/searchtrips"/>
)
}
export default ProtectedRouteAdmin