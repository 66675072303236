import React , { useState , useEffect , useContext} from 'react'
import { useNavigate } from "react-router-dom";
import TripIdFromFdrContext from '../../../hooks/TripIdFromFdr';
import Cookies from 'js-cookie';
import CustomButton from '../../CustomButton';
import Nodata from '../../../Assets/Images/Aucun.png'
import  toast , { Toaster } from 'react-hot-toast';
import { GetTripById } from '../../../Utils/Requests'
import Modal from '@mui/material/Modal'
import { Box } from '@mui/material';
import CarSeats from '../seats/seatsReservation';
import PlacesCount from '../../../hooks/SeatesChoosedContext';
import PlaceAd from '../../../hooks/PlaceContext'
import { CreateTicketWithDataSeats , CreateTicketsWithIncrementedSeats } from '../../../Utils/Requests';

function TicketFromFdr(props) {


  const { place , setPlace } = useContext(PlaceAd)

  const { tripIdFromFdr , setTripIdFromFdr , seatsTripsFromFdr , setSeatsTripsFromFdr , itinerary  } = useContext(TripIdFromFdrContext)
  const token = Cookies.get('token')
  const [ticketData , setTicketData] = useState({})
  const [fdrId,setFdrId] = useState('')
  const [payment,setPayment] = useState('cash')
  const [trip_id,setTripId] = useState('')

  const navigate = useNavigate()
  // context for choosen places : 
  const { placesChoosen  , setPlacesChoosen} = useContext(PlacesCount)


  // for handling payment method : 
  const handleOptionChange = (e) => {
    setPayment(e.target.value)
  }

    // MODAL : 
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => { 
    setOpen(false) 
    }
  const [success,setSuccess] = useState(false)
  
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 900,
    bgcolor: 'background.paper',
    borderRadius : '20px',
    p: 4,
  };

  useEffect(() => {
      try {
        GetTripById(tripIdFromFdr,token).then((data) => {
          setTripId(data.trip_id)
          console.log(data);
          setTicketData(data)
          setSuccess(true)
          // console.log(data)
          }).catch((err) => {
            setSuccess(false)
            console.log(err)
        })
      } catch (error) {
          setSuccess(false)
          console.log(error);
      }
  },[tripIdFromFdr])

  const handleFdrId = (id) => {
    setFdrId(id)
  }

  const Plus = () => {
    setPlace(place + 1)
  }

  const Moins = () => {
    setPlace(place - 1)
  }

  function handleIsValidPayment(payment){
    if(payment === "cash"){
      if(placesChoosen.length === 0 || placesChoosen.every((e) => e === null)){
         try {
          CreateTicketsWithIncrementedSeats(token,trip_id,place).then(() => {
            toast.success("Created Successfully With Random Seats")
            setPlacesChoosen([])
            setTripIdFromFdr('')
            setPlace(1)
            navigate('/Completed')
          }).catch(() => {
            toast.error('Server Error , try again please')
          })
         } catch (error) {
          toast.error('Server Error , try again please')
         }
      } else if(placesChoosen.length > 0 && placesChoosen.every((element) => element !== null)){
        try {
          CreateTicketWithDataSeats(token,trip_id,{
            seat_numbers: placesChoosen
          }).then(() => {
            toast.success(`Created Successfully With Places ${placesChoosen.map((e) => e)}`)
              setPlacesChoosen([])
              setTripIdFromFdr('') 
              setPlace(1)
              navigate('/Completed')
            }).catch(() => {
            toast.error('Error Server ,Seats Data Request')
          })
        } catch (error) {
          toast.error('Error Server ,Seats Data Request')
        }
          } else if (placesChoosen.length > 0 && placesChoosen.includes(null)){
          toast.error('please fill all seats and try Again !')
          }
    } else if (payment === "tpe") {
        console.log('tpe');
    }
  }

  return (
    <>
      <div>
     {
     tripIdFromFdr 
     ?
     <div className="flex flex-col">
     <div className="bg-red-600 text-white my-2 py-2 mx-2 px-2 text-center">
     <p>{itinerary.map((e) => <span className='mx-3'>{e.arrival_city}</span>)}</p>
     </div>
     <p
       className="mt-4  mx-auto"
       style={{ fontSize: "25px", fontWeight: "bold" }}
     >
       Réservation Du Billet
     </p>
     <p className="mx-auto my-2">
       Ticket Du Bus de{" "}
       <span className="font-bold text-red-600">
         {ticketData.departure_city}
       </span>{" "}
       et a destination de :{" "}
       <span className="font-bold text-red-600">
         {ticketData.arrival_city}
       </span>{" "}
     </p>
     <p className="mx-auto font-extrabold border-2 px-4 bg-red-700 text-white">
        {ticketData.date_time}
       {/* 2023-07-11 */}
     </p>
     {/* Ste transport */}
     <div className="allContent my-3 flex mx-5 ">
       <p className="font-bold" style={{ fontSize: "19px" }}>
         {" "}
         Ste transport :
       </p>
       <p className="text-red-600 mx-3 " style={{ fontSize: "19px" }}>
         {ticketData.bus_company_name}
       </p>
     </div>
     {/* date */}
     <div className="allContent my-3 flex mx-5 ">
       <p className="font-bold" style={{ fontSize: "19px" }}>
         {" "}
         Heure D'épart :
       </p>
       <p className="text-red-600 mx-3" style={{ fontSize: "19px" }}>
         {ticketData.departure_time}
       </p>
     </div>
     {/* date */}
     <div className="allContent my-3 flex mx-5 ">
       <p className="font-bold" style={{ fontSize: "19px" }}>
         {" "}
         Heure D’arrivé :{" "}
       </p>
       <p className="text-red-600 mx-3" style={{ fontSize: "19px" }}>
         {ticketData.arrival_time}
       </p>
     </div>
     {/* nombre de place */}
     <div className="allContent my-3 flex mx-5 gap-2 ">
       <p className="font-bold" style={{ fontSize: "19px" }}>
         {" "}
         Nombre de place(s) :
       </p>
       {place <= 1 ? <></> : <div className='bg-red-500 text-white rounded-lg px-2  '>
          <button onClick={Moins}>-</button>
        </div>}
        
       <p className="text-red-600 mx-3" style={{ fontSize: "19px" }}>
        {place}
       </p>
       {place >= 4 ? <></> : <div className='bg-red-500 text-white rounded-lg  px-2  '>
          <button onClick={Plus}>+</button>
        </div>} 
       
       {ticketData.is_numeral == "true" ? <></> : <button onClick={() => {handleOpen();handleFdrId(ticketData.fdr_id)}}  className='bg-red-500 ml-4  px-3 rounded-lg text-white'>Choisir Les Places</button>}
                       <Modal
                       open={open}
                       onClose={handleClose}
                       aria-labelledby="modal-modal-title"
                       aria-describedby="modal-modal-description"
                     >
                       <Box  className="" sx={style}>
                         <div className="  rounded-lg border  border-black">
                          {/* content for places here  */}
                          <CarSeats place={place}  fdrId={fdrId} />
                         </div>
                         <div className='flex justify-end'>
                             <button onClick={handleClose} className='bg-red-600 px-4 py-3 text-white rounded-md  mt-4'>Select Seats</button>
                         </div>
                       </Box>
                     </Modal>
     </div>
     {/* Prix Total  */}
     {/* {placesChoosen.map((e) => ( <h1 className="text-red-600 font-bold text-1xl px-3 border-1 border-red">{e}</h1>))}
 */}
     <div className="allContent my-3 flex mx-5 ">
       <p className="font-bold" style={{ fontSize: "19px" }}>
         {" "}
         Prix :
       </p>
       <p
         className="text-red-600 font-bold mx-3 text-1xl"
         style={{ fontSize: "19px" }}
       >
         {place * ticketData.price} DH
       </p>
     </div>

     {/* Payment  */}
     <div className="allContent my-3 flex mx-5 ">
       <p className="font-bold" style={{ fontSize: "19px" }}>
         {" "}
         Paiment :
       </p>
       <p className="text-yellow-400 font-bold mx-3 text-1xl">
         {payment ? (
           <p className="text-green-700" style={{ fontSize: "19px" }}>
             {payment}
           </p>
         ) : (
           <p className="text-orange-500" style={{ fontSize: "19px" }}>
             En Attente
           </p>
         )}
       </p>
     </div>

     {/* payment options */}
     <div>
       <div className="flex items-center my-3  mx-8">
         <input
           value="cash"
           checked={payment == "cash"}
           onChange={handleOptionChange}
           name="cash"
           id="default-radio-1"
           type="radio"
           className="text-red-500"
         />
         <label
           htmlFor="default-radio-1"
           className="ml-2 text-sm font-medium text-gray-900"
           style={{ fontSize: "19px" }}
         >
           Paiement Cash
         </label>
       </div>
       <div className="flex items-center mx-8">
         <input
           value="tpe"
           onChange={handleOptionChange}
           checked={payment == "tpe"}
           name="tpe"
           id="default-radio-2"
           type="radio"
           className="w-4 h-4 text-red-600 bg-gray-100 border-gray-300 focus:ring-2"
         />
         <label
           htmlFor="default-radio-2"
           className="ml-2 text-sm font-medium text-gray-900"
           style={{ fontSize: "19px" }}
         >
           Paiement Avec Carte Banquaire
         </label>
       </div>
     </div>
     {/* btns */}
     <div className="flex mt-6 mx-auto gap-6">
      {place > 0 ? <CustomButton
         onClick={() => {handleIsValidPayment(payment)}}
         type="submit"
         content="Acheter Ticket"
       /> : <></> }
       
       <button
         onClick={() => {setTripIdFromFdr('');setPlace(1)}}
         className=" inline-block  rounded border border-[#EA022E]  px-16 py-3.5 text-sm font-medium text-red-600 hover:bg-red-600 hover:text-white"
       >
         Annuler
       </button>
     </div>
     <Toaster position="top-center"
      reverseOrder={false} />
   </div>
     :
     <div className='flex flex-col justify-center items-center mt-[10rem] '>
        <img src={Nodata} alt='Aucune Ticket Selectionné' />
        <p className='font-bold'>Aucune Ticket N'est Selectionné</p>
     </div> 
     }
    </div>
    </>
  )
}

export default TicketFromFdr
