import React from 'react'
import { useEffect , useState } from 'react'
import BarAdd from '../../../../components/Admin/BarAdd'
import { useNavigate , useParams } from 'react-router-dom'
import CustomButton from '../../../../components/CustomButton'
import CustomInput from '../../../../components/CustomInput'
import CustomLabel from '../../../../components/CustomLabel'
import Cookies from 'js-cookie'
import { UpdatePrinterByID } from '../../../../Utils/Requests'
import FormWrapper from '../../../../components/Admin/FormWrapper'



const PutIpUser = () => {

    const navigate = useNavigate()
    const token = Cookies.get('token')
    const param  = useParams()
    const [user_ip, setUserIp] = useState(param.user_ip)
    const [printer_ip,setPrinterIp] = useState(param.printer_ip)

    const UpdateHandler = (e) => {
        e.preventDefault()    
        const data = {
            user_ip,
            printer_ip
        }
        try {
            UpdatePrinterByID(token,data,user_ip).then(() => {
                navigate('/Printers')
            }).catch((err) => {
                console.log(err);
            })
        } catch (error) {
            console.log(error);
        }
    }


  return (
    <div>
        <BarAdd title="Update Printer IP" />
        <FormWrapper
        title="Update Printer IP"
        Children={
        <>
        <CustomLabel label="User IP" />
        <CustomInput type="text" onChange={(e) => {setUserIp(e.target.value)}} value={user_ip} />
        <CustomLabel label="Printer IP" />
        <CustomInput type="text" onChange={(e) => {setPrinterIp(e.target.value)}} value={printer_ip} />
        <CustomButton content="submit" type="submit" onClick={UpdateHandler}  />
        </>
        }
        
        />
    </div>
  )
}

export default PutIpUser
