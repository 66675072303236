import React from 'react'
import BarAdd from '../../components/Admin/BarAdd'
import GetBusStation from '../../components/Admin/data/GET/GetBusStation'

function BusStations() {
  return (
    <div>
      <BarAdd 
        to="/addbusStation" 
        title="Bus Stations List" 
        content="Add Bus Station +" 
        style={"px-4 bg-white mr-5 py-2 rounded-lg"}
      />
    <GetBusStation />
    </div>
  )
}

export default BusStations
