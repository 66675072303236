import React from 'react'
import { Link } from 'react-router-dom'

function FirstProgressBar() {
    return (
        <>
            <div class="hidden md:block">
                <nav aria-label="Site Nav ">
                    <ul class="flex items-center gap-6 text-sm">
                        <li>
                            <Link
                                class="text-black transition ml-4"
                            >
                                Recherche
                            </Link>
                        </li>
                        <span className='mx-4'>
                            <svg width="46" height="10" viewBox="0 0 46 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="5" cy="5" r="5" fill="black" />
                                <circle cx="41" cy="5" r="4.5" stroke="black" />
                                <path d="M9 5H36.5" stroke="black" />
                            </svg>
                        </span>
                        <li>
                            <Link
                                class="text-black transition ml-4"
                            >
                                Réservez
                            </Link>
                        </li>
                        <span className="mx-4">
                            <svg width="46" height="10" viewBox="0 0 46 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="5" cy="5" r="4.5" stroke="black" />
                                <circle cx="41" cy="5" r="4.5" fill="black" stroke="black" />
                                <path d="M9 5H36.5" stroke="black" />
                            </svg>
                        </span>
                        <li>
                            <Link
                                class="text-black transition"
                            >
                                Paiment
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </>
    )
}

export default FirstProgressBar
