import React from 'react'
import CardHome from '../../components/Admin/CardHome'

function Home() {
  return (
    <div className='flex flex-row justify-evenly'>
        <CardHome Number="12" content="Bus Companies" />
    </div>
  )
}

export default Home
