import React from 'react'
import BarAdd from '../../components/Admin/BarAdd'
import GetAllowedRoutes from '../../components/Admin/data/GET/GetAllowedRoutes'

function AllowedRoutes() {
  return (
    <div>
      <BarAdd
      to="/addAllowedRoutes"
      title="Allowed Routes Lists"
      content="Add Allowed Route +"
      style={"px-4 bg-white mr-5 py-2 rounded-lg"}
      />
      <GetAllowedRoutes />
    </div>
  )
}

export default AllowedRoutes
