import React from 'react'
import { Link } from 'react-router-dom'

function BarAdd({ title, content, style, to }) {
  return (
    <div className='bg-[#F0012A] py-3 mr-3 rounded-lg flex  justify-between'>
      <h1 className='pl-4 text-white  py-2 text-1xl'>{title}</h1>
      {(to && content) && <Link to={to} className={style}>{content}</Link>}
    </div>
  )
}
export default BarAdd
