import Navbar from "../components/Navbar"
import HeroSection from "../components/HeroSection"
import SearchBar from '../components/SearchBar'
import Background from '../Assets/Images/background.png'


function SearchTrips() {


  return (
    <div>
      {/* navarbar */}
      <div className="mb-16">
      <Navbar />
      </div>
      <div style={{ backgroundImage: `url(${Background})`}} className="py-16">
      <HeroSection />
      <SearchBar />
      </div>
    </div>
  )
}

export default SearchTrips
