import React from 'react'
import BarAdd from '../../components/Admin/BarAdd'
import GetBus from '../../components/Admin/data/GET/GetBus'

function Bus() {
  return (
    <div>
    <BarAdd to="/addbus" title="Bus List" content="Add Bus +" style={"px-4 bg-white mr-5 py-2 rounded-lg"} />
    <GetBus />
    </div>
  )
}

export default Bus
