import { createContext , useState } from "react";

const TripsLists = createContext()

const TripsListsProvider = ({children}) => {
    const [listOfTrips,setListOfTrips] = useState([])
    const [seatsContext,setSeatsContext] = useState(0)
    return (
        <TripsLists.Provider value={{listOfTrips,setListOfTrips,seatsContext,setSeatsContext}}>
            {children}
        </TripsLists.Provider>
    )
}

export default TripsLists;
export {TripsListsProvider}
