import React from 'react'

function CardHome( {Number , content }) {
    return (
        <div class="flex flex-col align-center rounded-sm border-t-4 border-pink-600  shadow-xl sm:p-6 lg:p-8">
            <div class="flex items-center gap-4"><h3 class="text-3xl font-bold sm:text-4xl">{Number}</h3></div>
            <p class="mt-4 font-medium text-gray-500">{content}</p>
        </div>
    )
}

export default CardHome
