import React, { useEffect } from "react";
import CustomButton from "./CustomButton";
import CustomInput from "./CustomInput";
import "../App.css";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GetListOfCities } from "../Utils/Requests";
import Cookies from "js-cookie";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Toaster, toast } from "react-hot-toast";

function SearchBar() {
  // retrieve state's :
  const token = Cookies.get("token");
  const [city, setCity] = useState("");
  const [datetime, setDateTime] = useState(dayjs());
  const [seats, setSeats] = useState(1);
  const [cities, setCities] = useState([]);
  const [error, setError] = useState(false);

  const navigate = useNavigate();
  // retrieve data and return state :
  function isOptionEqualToValue(option, value) {
    return option.title === value.title;
  }
  const handleOnChange = (event, value) => {
    setCity(value?.value);
  };
  // Handle DateTime :
  const handleDateChange = (date) => {
    setDateTime(date);
  };
  // Handle Number of seats :
  const handleSeats = (e) => {
    setSeats(e.target.value);
  };
  // retrieve token :

  useEffect(() => {
    try {
      GetListOfCities(token)
        .then((data) => {
          const options = data?.map((cit) => ({ label: cit, value: cit }));
          setCities(options);
          setError(false);
        })
        .catch(() => {
          setError(true);
        });
    } catch (error) {
      setError(true);
    }
  }, []);

  const handleSearch = () => {
    if (
      error ||
      city == "" ||
      city == undefined ||
      datetime == null ||
      datetime == undefined ||
      +seats < 1
    ) {
      toast.error("Please enter a valid data!", {
        position: "bottom-right",
      });
      return;
    }
    navigate(`/resultatTrip/${city}/${datetime}/${seats}`);
  };

  return (
    <div className="mt-16 pb-16">
      {/* container  */}
      <div className="flex flex-row justify-center  Customized">
        {/* Destination */}
        <div className="flex flex-row items-center  mr-6 ">
          <Autocomplete
            disablePortal
            id="cities"
            isOptionEqualToValue={isOptionEqualToValue}
            onChange={handleOnChange}
            options={cities}
            sx={{ width: 200 }}
            renderInput={(options) => (
              <TextField {...options} label="Destinations" />
            )}
          />
        </div>
        {/* end destination input */}
        <span>
          <svg
            width="4"
            height="72"
            viewBox="0 0 4 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M2 0L2 72" stroke="#EA022E" stroke-width="3" />
          </svg>
        </span>
        {/* Date */}
        <div className="flex flex-row items-center mx-4">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disablePast
              onError={() => setError(true)}
              onAccept={() => setError(false)}
              label="Date"
              format="DD-MM-YYYY"
              value={datetime}
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
        {/* end Date input */}
        <span>
          <svg
            width="4"
            height="72"
            viewBox="0 0 4 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M2 0L2 72" stroke="#EA022E" stroke-width="3" />
          </svg>
        </span>
        {/* seats */}
        <div className="flex flex-row items-center mx-4">
          <TextField
            onChange={handleSeats}
            label="Number of Seats"
            type="number"
            sx={{ width: 150 }}
            inputProps={{ min: 1, max: 80 }}
          />
        </div>
        {/* seats input */}
        <span>
          <svg
            width="4"
            height="72"
            viewBox="0 0 4 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M2 0L2 72" stroke="#EA022E" stroke-width="3" />
          </svg>
        </span>
        {/* Search btn */}
        <div className="flex flex-row items-center mx-2 ml-4">
          <CustomButton
            type="submit"
            content="Chercher"
            onClick={handleSearch}
          />
        </div>
        {/* end btn search */}
      </div>
      <Toaster />
    </div>
  );
}

export default SearchBar;
