import { Backdrop } from "@mui/material";

const GetAllowedRoutesBackdrop = ({ open, handleClose, itinerary, name }) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={handleClose}
    >
      <div className="bg-inherit p-5 rounded-lg w-full max-w-2xl max-h-full">
        <div class="flex gap-5 items-center justify-between border-b rounded-t">
          <h3 class="text-xl font-medium text-white">
            {name}
          </h3>
          <button
            type="button"
            class="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-600 hover:text-white"
            data-modal-hide="top-left-modal"
          >
            <svg
              aria-hidden="true"
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>

        <div className="flex flex-col mt-5">
          {itinerary.map((it) => (
            <div className="flex flex-row justify-between">
              <p className="text-lg font-medium">{it}</p>
              <p className="text-lg font-medium">-</p>
            </div>
          ))}
        </div>
      </div>
    </Backdrop>
  );
};

export default GetAllowedRoutesBackdrop;
