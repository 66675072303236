import React, { useEffect, useState } from "react";
import BarAdd from "../../../../components/Admin/BarAdd";
import FormWrapper from "../../../../components/Admin/FormWrapper";
import CustomLabel from "../../../../components/CustomLabel";
import CustomInput from "../../../../components/CustomInput";
import CustomButton from "../../../../components/CustomButton";
import {
  GetAllCompanies,
  createAgreement,
  getAllBuses,
} from "../../../../Utils/Requests";
import Cookies from "js-cookie";
import { useMessage } from "../../../../hooks/MessageContext";
import { useNavigate } from "react-router-dom";
import { Autocomplete, TextField } from "@mui/material";

function AddAgreement() {
  const [agreement_uid, setAgreement_uid] = useState("");
  const [expiration_date, setExpiration_date] = useState("");
  const [bus_company_id, setBus_company_id] = useState("");
  const [bus_id, setBus_id] = useState(null);

  const [buses, setBuses] = useState([]);
  const [bus_companies, setBus_companies] = useState([]);

  const token = Cookies.get("token");
  const { setMessage } = useMessage();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const buses_data = await getAllBuses(token);
        const bus_companies_data = await GetAllCompanies(token);

        const bus_companies = bus_companies_data.map((company) => ({
          title: company.name,
          id: company.bus_company_id,
        }));
        const buses = buses_data.map((bus) => ({
          title: bus.license_plate,
          id: bus.bus_id,
        }));

        setBuses(buses);
        setBus_companies(bus_companies);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      agreement_uid,
      expiration_date,
      bus_company_id,
      bus_id,
    };
    try {
      await createAgreement(data, token);
      setMessage("Agreement created successfully");
      navigate("/agreements");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <BarAdd title="Add Agreement" />
      <FormWrapper
        title="Add Agreement"
        Children={
          <>
            <CustomLabel label="AGREEMENT UID	" />
            <CustomInput
              id="AGREEMENT UID	"
              name="AGREEMENT UID	"
              type="text"
              placeholder="Agreement UID"
              value={agreement_uid}
              onChange={(e) => setAgreement_uid(e.target.value)}
            />
            <CustomLabel label="Expiration Date" />
            <CustomInput
              id="Expiration Date"
              name="Expiration Date"
              type="date"
              placeholder="Expiration Date"
              value={expiration_date}
              onChange={(e) => setExpiration_date(e.target.value)}
            />
            <CustomLabel label="Bus ID" />
            <Autocomplete
              onChange={(event, value) =>
                value?.id ? setBus_id(value.id) : setBus_id(null)
              }
              options={buses}
              getOptionLabel={(option) => option.title}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField {...params} label="Select Bus" />
              )}
            />
            <CustomLabel label="Bus Company ID" />
            <Autocomplete
              onChange={(event, value) =>
                value?.id
                  ? setBus_company_id(value.id)
                  : setBus_company_id(null)
              }
              options={bus_companies}
              getOptionLabel={(option) => option.title}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField {...params} label="Select Bus Company" />
              )}
            />
            <CustomButton type="submit" content="Add" onClick={handleSubmit} />
          </>
        }
      />
    </>
  );
}

export default AddAgreement;
