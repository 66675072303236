import React, { useEffect, useState } from "react";
import BarAdd from "../../../../components/Admin/BarAdd";
import FormWrapper from "../../../../components/Admin/FormWrapper";
import CustomLabel from "../../../../components/CustomLabel";
import CustomInput from "../../../../components/CustomInput";
import CustomButton from "../../../../components/CustomButton";
import { GetAllCompanies, createBus } from "../../../../Utils/Requests";
import Cookies from "js-cookie";
import Alert from "../../../../components/Alert";
import { useNavigate } from "react-router-dom";
import { useMessage } from "../../../../hooks/MessageContext";
import { Autocomplete, TextField } from "@mui/material";
import {
  InputLabel,
  MenuItem,
  Select,
  FormControl
} from "@mui/material";

function Bus() {
  const token = Cookies.get("token");
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("Something went wrong!");
  const [license_plate, setLicense_plate] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [capacity, setCapacity] = useState(null);
  const [expiry_date_of_insurance, setExpiry_date_of_insurance] = useState("");
  const [
    expiry_date_of_technical_inspection,
    setExpiry_date_of_technical_inspection,
  ] = useState("");
  const [expiry_date_of_registration, setExpiry_date_of_registration] =
    useState("");
  const [rfid_tag, setRfid_tag] = useState("");
  const [bus_company_id, setBus_company_id] = useState(null);
  const [bus_companies, setBus_companies] = useState([]);
  const [is_numeral , setIsNumeral] = useState(true)

  const navigate = useNavigate();
  const { setMessage: setSuccessMessage } = useMessage();

  useEffect(() => {
    (async () => {
      try {
        const bus_companies = await GetAllCompanies(token);
        setBus_companies(bus_companies);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      license_plate,
      make,
      model,
      capacity,
      expiry_date_of_insurance,
      expiry_date_of_technical_inspection,
      expiry_date_of_registration,
      rfid_tag,
      bus_company_id,
      is_numeral
    };

    if (Object.values(data).some((field) => field === "" || field === null)) {
      setMessage("Please fill all the fields");
      setError(true);
      return;
    }

    try {
      await createBus(data, token);
      setSuccessMessage("Bus added successfully");
      navigate("/bus");
    } catch (error) {
      console.log(error);
      setMessage("Something went wrong!");
      setError(true);
    }
  };
  return (
    <>
      <BarAdd title="Add Bus" />
      <FormWrapper
        title="Add Bus"
        Children={
          <>
            {error && (
              <Alert
                className="flex mx-auto p-4 mb-4 mt-7 w-64 text-sm text-red-800 rounded-lg bg-red-100"
                message={message}
              />
            )}
            <CustomLabel label="license plate" />
            <CustomInput
              id="license plate"
              name="license plate"
              type="text"
              placeholder="license plate"
              onChange={(e) => setLicense_plate(e.target.value)}
            />
            <CustomLabel label="make" />
            <CustomInput
              id="make"
              name="make"
              type="text"
              placeholder="make"
              onChange={(e) => setMake(e.target.value)}
            />
            <CustomLabel label="model" />
            <CustomInput
              id="model"
              name="model"
              type="text"
              placeholder="model"
              onChange={(e) => setModel(e.target.value)}
            />
            <CustomLabel label="capacity" />
            <CustomInput
              id="capacity"
              name="capacity"
              type="number"
              placeholder="capacity"
              onChange={(e) => setCapacity(e.target.value)}
            />
            <CustomLabel label="expiry date of insurance" />
            <CustomInput
              id="expiry date of insurance"
              name="expiry date of insurance"
              type="date"
              placeholder="expiry date of insurance"
              onChange={(e) => setExpiry_date_of_insurance(e.target.value)}
            />
            <CustomLabel label="expiry date of technical inspection" />
            <CustomInput
              id="expiry date of technical inspection"
              name="expiry date of technical inspection"
              type="date"
              placeholder="expiry date of technical inspection"
              onChange={(e) =>
                setExpiry_date_of_technical_inspection(e.target.value)
              }
            />
            <CustomLabel label="expiry date of registration" />
            <CustomInput
              id="expiry date of registration"
              name="expiry date of registration"
              type="date"
              placeholder="expiry date of registration"
              onChange={(e) => setExpiry_date_of_registration(e.target.value)}
            />
            <CustomLabel label="Rfid tag" />
            <CustomInput
              id="Rfid tag"
              name="Rfid tag"
              type="text"
              placeholder="Rfid tag"
              onChange={(e) => setRfid_tag(e.target.value)}
            />
            <CustomLabel label="is_Numeral" />
            <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">is Numeral</InputLabel>
                  <Select
                    label="is Numeral"
                    onChange={(e) =>
                      e.target.value ? setIsNumeral(true) : setIsNumeral(false)
                    }
                  >
                    <MenuItem value={true}>true</MenuItem>
                    <MenuItem value={false}>false</MenuItem>
                  </Select>
            </FormControl>
            <CustomLabel label="bus company" />
            <Autocomplete
              onChange={(event, value) =>
                value?.bus_company_id
                  ? setBus_company_id(value.bus_company_id)
                  : setBus_company_id(null)
              }
              options={bus_companies}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField {...params} label="Select Bus Company" />
              )}
            />
            <CustomButton type="submit" content="Add" onClick={handleSubmit} />
          </>
        }
      />
    </>
  );
}

export default Bus;
