import React from "react";
import CustomButton from "./CustomButton";
import CustomInput from "./CustomInput";
import CustomLabel from "./CustomLabel";
import Logo from "../Assets/Images/logo.png";
import { Link } from "react-router-dom";
import { useState , useContext } from "react";
import { Login } from "../Utils/Requests";
import Alert from "./Alert";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import GareName from "../hooks/GareNameContext";

function FormLogin() {

  const { setNameGare } = useContext(GareName)

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [succes, setSucces] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  // state to test Inputs
  const [isEmpty, setIsEmpty] = useState("");
  const [errorField, setErrorField] = useState(false);

  const handleEmail = (e) => {
    return setUsername(e.target.value);
  };

  const handlePassword = (e) => {
    return setPassword(e.target.value);
  };

  const LoginHandler = async (e) => {
    e.preventDefault();
    // if(!InputValidation.isValidEmail(username)){
    //   setUsernameErr('Please enter a valid email')
    // }
    // if(!InputValidation.isValidPassword(password)){
    //   setPasswordErr('please enter a password')
    // }
    if (username === "") {
      setErrorField(true);
      setError(false);
      setIsEmpty("Veuillez entrer votre Email et Mot de passe");
      return;
    }
    if (password === "") {
      setErrorField(true);
      setError(false);
      setIsEmpty("Veuillez entrer votre Mot de Passe");
      return;
    }
    const user = {
      username,
      password,
    };
    try {
      const data = await Login(user);
      setNameGare(data.bus_station_location)
      setSucces(true);
      setError(false);
      setErrorField(false);
      Cookies.set("role", data.user_role);
      Cookies.set("token", data.access_token);
      switch (data.user_role) {
        case "superadmin":
          navigate("/home");
          break;
        case "cashier":
          navigate("/searchtrips");
          break;
        case "chef-de-gare":
          navigate("/searchtrips");
        case "cashier_advanced":
          navigate("/searchtripsAdvancedCashier")
          break;
      };
    } catch (error) {
      setError(true);
      setSucces(false);
      setErrorField(false);
      setMessage(error.response.data.detail);
    }
  };

  const navigate = useNavigate();

  return (
    <form>
      <div className="flex flex-col  items-center px-4">
        <img src={Logo} alt="logo grv" />
        <h1 className="text-1xl font-bold">Se Connecter</h1>
        <div className="px-4" style={{ marginTop: "83px" }}>
          {/* alert */}
          {error && (
            <Alert
              className="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50"
              message={message}
            />
          )}
          {succes && (
            <Alert
              className="flex p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 message="
              message="Login Successfully"
            />
          )}
          {errorField && (
            <Alert
              className="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50"
              message={isEmpty}
            />
          )}

          {/* Input Email */}
          <CustomLabel for="email" label="Email *" />
          <CustomInput
            style={{ width: "358px" }}
            name="email"
            onChange={handleEmail}
            type="text"
            placeholder="example@youremail.com"
          />
        </div>
        {/* Input Password */}
        <div className="px-4" style={{ marginTop: "23px" }}>
          <CustomLabel for="motDePasse" label="Mot de passe *" />
          <CustomInput
            style={{ width: "358px" }}
            name="password"
            onChange={handlePassword}
            type="password"
            placeholder="**********"
          />
        </div>
        {/* button send  */}
        <div className="" style={{ marginTop: "63px" }}>
          <CustomButton
            onClick={LoginHandler}
            type="submit"
            content="Se Connecter"
          />
        </div>
      </div>
    </form>
  );
}

export default FormLogin;
