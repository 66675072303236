import React from 'react'
import BarAdd from '../../components/Admin/BarAdd'
import GetTrips from '../../components/Admin/data/GET/GetTrips'

function Trip() {
  return (
    <div>
    <BarAdd title="Trip List" style={"px-4 bg-white mr-5 py-2 rounded-lg"} />
    <GetTrips />
    </div>
  )
}

export default Trip