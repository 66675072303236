import React , { useState } from 'react'
import BarAdd from '../../../../components/Admin/BarAdd'
import FormWrapper from '../../../../components/Admin/FormWrapper'
import CustomLabel from '../../../../components/CustomLabel'
import CustomInput from '../../../../components/CustomInput'
import CustomButton from '../../../../components/CustomButton'
import { CreateBusCompany } from '../../../../Utils/Requests'
import Cookies from "js-cookie"
import { useNavigate } from 'react-router-dom'
import Alert from '../../../../components/Alert'

function BusCompaniesName() {

  const navigate = useNavigate()
  const token = Cookies.get('token')
  const [name, setName] = useState('')
  const [error , setError] = useState(false)
  const [message , setMessage] = useState('')

  const handleName = (e) => {
    return setName(e.target.value)
  }

  const AddHandler = async (e) => {
    e.preventDefault()
    const data = {
      name
    }
    try {
    CreateBusCompany(token,data).then(() => {
    setError(false)
    navigate('/buscompanies')
    }).catch(() => {
    setError(true)
    setMessage('Server Error , Try Again or try another bus Name company')
    })
    } catch (error) {
    setError(true)
    setMessage('Server Error , Try Again or try another bus Name company')
    }
  }

  return (
    <>
    <BarAdd title="Add Bus company" />
    {error && <Alert className="flex mx-auto p-4 mb-4 mt-7 w-64 text-sm text-red-800 rounded-lg bg-red-100"  message={message} />}
    <FormWrapper
        title="Add Bus Company Name"
        Children={
            <>
            <CustomLabel label="Bus Company Name" />
            <CustomInput
            onChange={handleName}
            id="Name Company"   
            name="nameCompany"
            type="text"
            placeholder="Name Company"
            />
            <CustomButton
            onClick={AddHandler}
            type="submit" 
            content="Add"
            />
            </>
        }
     />
    </>
  )
}

export default BusCompaniesName
