import React from 'react'
import BarAdd from '../../components/Admin/BarAdd'
import GetUsers from '../../components/Admin/data/GET/GetUsers'

function Users() {
  return (
    <div>
      <BarAdd
       to="/AddUsers"
       title="Users List"
       content="Add User +"
       style={"px-4 bg-white mr-5 py-2 rounded-lg"}
      />
      <GetUsers />
    </div>
  )
}

export default Users
