import CustomButton from "./CustomButton";
import Nodata from "../Assets/Images/Aucune.png";
import { useParams } from "react-router-dom";
import { SearchTrips } from "../Utils/Requests";
import { useState, useEffect, useContext } from "react";
import Cookies from "js-cookie";
import TripIdContext from "../hooks/TripIdContext";
import ChoosenItinerary from "../hooks/ChoosenItinerary"
import toast, { Toaster } from "react-hot-toast";
import { FormatDate } from "../helpers/FormatDate";
import moment from "moment";
import { DataGrid } from '@mui/x-data-grid';


// 
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
//
//
//

function TableTrips() {

  const { setDataItinerary } = useContext(ChoosenItinerary)

  //
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //


  //

  //

  //
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    height: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  //
  // state context:
  const {
    setTripId,
    setShowData,
    setQuantity,
    quantity,
    shouldRefetch,
    setShouldRefetch,
  } = useContext(TripIdContext);
  const { city, datetime, min_seats } = useParams();

  // this code is just to REVERSE DATE FORMAT FROM Normal Object to : 'YYYY-MM-DD'
  const dateTime = FormatDate(datetime);
  // const DateBestFormat = moment(dateTime, "DD-MM-YYYY").format("YYYY-MM-DD");
  const [dataFetched, setDataFetched] = useState([]);
  const [error, setError] = useState(false);
  const token = Cookies.get("token");

  console.log("date" + dateTime);
  // Getting Data :
  useEffect(() => {
    try {
      SearchTrips(city, dateTime, token, min_seats)
        .then((data) => {
          setDataFetched(data);
        })
        .catch(() => {
          setError(true);
        });
      setShouldRefetch(false);
    } catch (error) {
      setError(true);
    }
  }, [shouldRefetch]);

  // make function with 2 Roles (SETidTRIP to contect & Show toast ):
  function TripSelect(id, data) {
    setTripId(id);
    setShowData(true);
    setQuantity([{ id: id, quantity: 1 }]);
    toast.success(`Voyage A ${city} Séléctionne Avec Succes`, {
      position: "bottom-right",
    });
    setDataItinerary(data)
  }

  function ConvertTime(x) {
    const date = new Date(x);
    const outputDate = date.toISOString().replace('T', ' ').replace(/\.\d{3}Z$/, '');
    return outputDate;
  }

  const [rowSelected, setRowSelected] = useState()

  // COLUMNS AND ROWS FOR TABLE MUI
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'number',
      width: 40,
    },
    {
      field: 'destination',
      headerName: 'Destination',
      type: 'string',
      flex: 3
    },
    {
      field: 'DateTime',
      headerName: 'date / Time',
      type: 'number',
      flex : 3
    },
  ];

  const rows = rowSelected?.map((row) => (
    {
      id: row?.ticket_id,
      destination: row?.destination,
      DateTime: ConvertTime(row?.date_time),
    }
  ))



  return (
    <div class="overflow-x-auto ">
      {/* table */}

      {dataFetched.length > 0 ? (
        <table class="min-w-full divide-y-2 divide-gray-200 text-sm mt-5 ">
          <thead class="ltr:text-left rtl:text-right">
            <tr>
              <th class="whitespace-nowrap px-2 py-2 font-bold text-gray-900">
                Heure de Départ
              </th>
              <th class="whitespace-nowrap px-2 py-2 font-bold text-gray-900">
                Heure D’arrivé
              </th>
              <th class="whitespace-nowrap px-2 py-2 font-bold text-gray-900">
                Prix
              </th>
              <th class="whitespace-nowrap px-2 py-2 font-bold text-gray-900">
                N’ De Place
              </th>
              <th class="whitespace-nowrap px-2 py-2 font-bold text-gray-900">
                Name Car Sociéte
              </th>
            </tr>
          </thead>

          {dataFetched.map((DATA) => (
            <tbody class="divide-y divide-gray-200 ">
              <tr class="odd:bg-gray-50 ">
                <td class="whitespace-nowrap px-4 py-3 text-gray-700">
                  {DATA.departure_time}{" "}
                  {DATA.departure_time?.slice(0, 2) < 12 ? "AM" : "PM"}
                </td>
                <td class="whitespace-nowrap px-4 py-3 text-gray-700">
                  {DATA.arrival_time}{" "}
                  {DATA.arrival_time?.slice(0, 2) < 12 ? "AM" : "PM"}
                </td>
                <td class="whitespace-nowrap font-bold px-4 py-3 text-red-700">
                  {DATA.price} DH
                </td>
                <td class="whitespace-nowrap font-bold px-4 py-3 text-red-700">
                  {DATA.availble_seats -
                    quantity.map((e) =>
                      e.id === DATA.trip_id ? e.quantity : 0
                    )}
                </td>
                <td class="whitespace-nowrap px-4 py-3 text-gray-700">
                  {DATA.bus_company_name}
                </td>
                <td class="whitespace-nowrap px-4 py-3 text-gray-700">
                  {DATA.availble_seats > 0 ? (
                    <div className="flex flex-row justify-end gap-5">
                      <CustomButton
                        onClick={() => TripSelect(DATA.trip_id, DATA.itinerary)}
                        content="Sélectionnez"
                      />
                      <button onClick={() => { handleOpen(); setRowSelected(DATA.tickets_sold_list) }} className="inline-block  rounded border border-[#EA022E] bg-white text-red-600 px-16 py-3.5 text-sm font-medium ">
                        Details
                      </button>
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box className="overflow-y-scroll" sx={style}>
                          <div className="my-3 ">
                            <h1 className="text-2xl font-bold">Total Tickets Sold : <span className="text-red-600 text-4xl">{DATA.tickets_sold}</span></h1>
                          </div>
                          <div class="overflow-x-auto  rounded-lg border border-gray-200">
                            <DataGrid
                              rows={rows}
                              columns={columns}
                              initialState={{
                                pagination: {
                                  paginationModel: { page: 0, pageSize: 8 },
                                },
                              }}
                              pageSizeOptions={[8, 16]}
                            />
                          </div>
                        </Box>
                      </Modal>
                    </div>
                  ) : (
                    <button class="inline-block rounded border border-[#EA022E] bg-white px-14 py-3.5 text-sm font-medium text-[#EA022E] opacity-70 cursor-not-allowed">
                      places Complet
                    </button>
                  )}
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      ) : (
        <div
          className="flex flex-col  items-center"
          style={{ height: "38rem" }}
        >
          <p className="my-16" style={{ fontSize: "25px", fontWeight: "bold" }}>
            Voyages Disponible{" "}
          </p>
          <img style={{ width: "18rem" }} src={Nodata} alt="" />
          <p className="text-center my-8 font-light">
            Aucune Resultat Pour <br /> Cette Date
          </p>
        </div>
      )}
      <Toaster />
    </div>
  );
}

export default TableTrips;
