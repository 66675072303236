import Background from '../Assets/Images/background.png'
import CustomButton from './CustomButton'
import { useNavigate } from 'react-router-dom'

function NotFound() {

  const nav = useNavigate()

  return (
    <>
      <div class="flex flex-col h-screen bg-white">
        <img
          src={Background}
          alt=""
          class="object-cover w-full h-64"
        />

        <div class="flex items-center justify-center flex-1">
          <div class="max-w-xl px-4 py-8 mx-auto text-center">
            <p className='text-red-600 text-8xl font-bold '>404</p>
            <h1 class="text-2xl mt-7 font-bold tracking-tight text-gray-900 sm:text-4xl">
              Nous ne pouvons pas trouver cette page.
            </h1>

            <p class="my-4 text-gray-500">
              Essayez à nouveau de chercher ou retournez chez vous pour recommencer depuis le début.
            </p>

            <CustomButton
              content="Retour"
              onClick={() => { nav('/searchtrips') }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFound
