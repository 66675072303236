import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import './LoaderCompleted.css'

function Completed() {

    const navigate = useNavigate()

    useEffect(() => {
        setTimeout(() => {
        navigate('/FeuilleDeRouteDateToday')
    },1500) 
    }, [])

    return (
        <div className='flex justify-center items-center mt-[10rem] gap-5'>
            <div className='my-5'>
            <p className="text-red-500 font-bold text-2xl">Ticked purchased Succesfully !</p>
            </div>
            <div className="spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Completed;
