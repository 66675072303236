import React from 'react'

function CustomLabel(props) {
  return (
    <label 
        htmlFor={props.for}
        className={`block text-black text-1xl ${props?.className}`}>
            {props.label}
    </label>
  )
}

export default CustomLabel
