import { createContext , useContext , useState } from "react";

const PlacesCount = createContext()

const PlaceProvider = ({ children }) => {
    const [placesChoosen,setPlacesChoosen] = useState([])
    return (
        <PlacesCount.Provider value={{ placesChoosen , setPlacesChoosen}}>
             {children}
        </PlacesCount.Provider>
    )
}

export default PlacesCount;
export {PlaceProvider}