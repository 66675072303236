import React from 'react'
import BarAdd from '../../components/Admin/BarAdd'
import GetPrinters from '../../components/Admin/data/GET/GetPrinters'

const Printers = () => {
  return (
    <div>
      <BarAdd title="Printers List" to="/AddPrinter" content="Add Printer IP" style={"px-4 bg-white mr-5 py-2 rounded-lg"}/>
      <GetPrinters />
    </div>
  )
}

export default Printers
