import React, { useContext, useEffect , useState } from 'react'
import { GetTripsFromFdrId } from '../../../Utils/Requests'
import Cookies from 'js-cookie'
import Nodata from '../../../Assets/Images/Aucune.png'
import CustomButton from '../../../components/CustomButton'
import TripIdFromFdrContext from '../../../hooks/TripIdFromFdr'
import PlaceAd from '../../../hooks/PlaceContext'


function TripsFromFdr(props) {
  
const token = Cookies.get('token') 

const [tripsData,setTripsData] = useState([])
const [succes,setSucces] = useState(false)

const { place } = useContext(PlaceAd)

const { setTripIdFromFdr , setSeatsTripsFromFdr , setItinerary } = useContext(TripIdFromFdrContext)

useEffect(() => {
    try {
    GetTripsFromFdrId(token,props.rowSelected.fdr_id).then((data) => {
        console.log(data)
        setTripsData(data)
        setSucces(true)
    }).catch((err) => {
        setTripsData([])
        setSucces(false)
    })
    } catch (error) {
        setTripsData([])
        setSucces(false)
    }
  },[])  

  return (
    <div>
     {tripsData.length > 0 
     ? 
        ( <table class="min-w-full divide-y-2 divide-gray-200 text-sm  ">
         <thead class="ltr:text-left rtl:text-right">
           <tr>
             <th class="whitespace-nowrap px-2 py-2 font-bold text-gray-900">
               Heure de Départ
             </th>
             <th class="whitespace-nowrap px-2 py-2 font-bold text-gray-900">
               Heure D’arrivé
             </th>
             <th class="whitespace-nowrap px-2 py-2 font-bold text-gray-900">
               Prix
             </th>
             <th class="whitespace-nowrap px-2 py-2 font-bold text-gray-900">
               N’ De Place
             </th>
             <th class="whitespace-nowrap px-2 py-2 font-bold text-gray-900">
               Type
             </th>
             <th class="whitespace-nowrap px-2 py-2 font-bold text-gray-900">
               Name Car Sociéte
             </th>
             <th class="whitespace-nowrap px-2 py-2 font-bold text-gray-900">
               Arrival City
             </th>
           </tr>
         </thead>

            {tripsData.map((DATA) => ( 
           <tbody class="divide-y divide-gray-200 ">
             <tr class="odd:bg-gray-50 ">
               <td class="whitespace-nowrap px-4 py-3 text-gray-700 text-center">
                 {DATA.departure_time}{" "}
                 {DATA.departure_time?.slice(0, 2) < 12 ? "AM" : "PM"}
               </td>
               <td class="whitespace-nowrap px-4 py-3 text-gray-700 text-center">
                 {DATA.arrival_time}{" "}
                 {DATA.arrival_time?.slice(0, 2) < 12 ? "AM" : "PM"}
               </td>
               <td class="whitespace-nowrap font-bold px-4 py-3 text-red-700 text-center">
                 {DATA.price} DH
               </td>
               <td class="whitespace-nowrap font-bold px-4 py-3 text-red-700 text-center">
                 {DATA.availble_seats === 0 ? DATA.availble_seats : DATA.availble_seats - place}
               </td>
               <td class="whitespace-nowrap px-4 py-3 text-gray-700 text-center">
                 D
               </td>
               <td class="whitespace-nowrap px-4 py-3 text-gray-700 text-center">
                 {DATA.bus_company_name}
               </td>
               <td class="whitespace-nowrap px-4 py-3 text-gray-700 text-center">
                 <p className=' py-2 px-3 text-black font-bold '>{DATA.arrival_city}</p>
               </td>
               <td class="whitespace-nowrap px-4 py-3 text-gray-700 text-center">
                   <div className="flex flex-row justify-end gap-5">
                    {DATA.availble_seats === 0 ?  <button className=' inline-block  rounded border border-[#EA022E]  px-16 py-3.5 text-sm font-medium text-red-500 '>Places Complet</button> : <CustomButton onClick={() => {setTripIdFromFdr(DATA.trip_id);setSeatsTripsFromFdr(DATA.availble_seats);setItinerary(DATA.itinerary)}}
                       content="Sélectionnez" />}
                   </div>
               </td>
             </tr>
           </tbody>))}
         </table>)
     : <div className='flex flex-col justify-center items-center mt-[4rem] '>
     <img src={Nodata} alt='Aucune Ticket Selectionné' />
     <p className='font-bold mt-5'>Aucun Trip N'est Trouvé</p>
  </div>}   
    </div>
  )
}

export default TripsFromFdr
