import { Backdrop, CircularProgress } from "@mui/material";
import './LoadingSpinner.css'

const LoadingSpinner = ({ open }) => {
  return (
    <Backdrop
      sx={{ color: "#ffff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <div class="spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Backdrop>
  );
};

export default LoadingSpinner;
