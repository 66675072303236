import React , { useState , useEffect } from 'react'
import BarAdd from '../../../../components/Admin/BarAdd'
import FormWrapper from '../../../../components/Admin/FormWrapper'
import CustomLabel from '../../../../components/CustomLabel'
import CustomInput from '../../../../components/CustomInput'
import CustomButton from '../../../../components/CustomButton'
import { GetAllBusStation , CreateUser } from '../../../../Utils/Requests'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import Alert from '../../../../components/Alert'

function AddUsers() {


  const token = Cookies.get('token')
  // state for Getting Data :
  const [busStation,setBusStation] = useState([])
  // state's For Adding A new User : 
  const [first_name, setFirstName] = useState('')
  const [last_name, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [role, setRole] = useState('')
  const [bus_station_id, setBusId] = useState(null)
  const [password ,setPassword] = useState('')
  // State For Errors:
  const [error,setError] = useState(false)
  const [message,setMessage] = useState('')
  // navigation: 
  const navigate = useNavigate()


  useEffect(  () => {
         GetAllBusStation(token).then((data) => {
          setBusStation(data)
         })
  }, [])
  // to get ID instead of name : 
  const idByName = busStation.reduce((acc, obj) => {
    acc[obj.name] = obj.bus_station_id;
    return acc;
  }, {});
  function getIdByName(name) {
    return idByName[name];
  }


  // Array of Roles : 
  const Roles = 
  [
    {role: "cashier"},
    {role: "superadmin"},
    {role: "chef-de-gare"}
  ]

  // send Data for adding User : 
  const addHandler = (e) => {
    e.preventDefault()
    const data = {
      first_name,
      last_name,
      email,
      role,
      bus_station_id,
      password
    }
    if(Object.values(data).some((field) => field === " " || field === null || field === undefined)){
      setError(true)
      setMessage('please fill all fields')
      return
    }
    try {
      CreateUser(token,data).then(() => {
        setError(false)
        navigate('/users')
      }).catch(() => {
        setError(true)
        setMessage('Server Error , try Again ...')
      })
    } catch (error) {
        setError(true)
        setMessage('Server Error , try Again ...')
    }
  }

  return (
    <>
      <BarAdd title="Add User" />
      {error && <Alert className="flex mx-auto p-4 mb-4 mt-7 w-64 text-sm text-red-800 rounded-lg bg-red-100" message={message}/>}
      <FormWrapper 
        title="Add User"
        Children={
            <>
            <CustomLabel label="FIRST NAME" />
            <CustomInput
            id="FIRST NAME"  
            onChange={(e) => {setFirstName(e.target.value)}} 
            name="FIRST NAME"
            type="text"
            placeholder="FIRST NAME"
            />
            <CustomLabel label="LAST NAME" />
            <CustomInput
            id="LAST NAME"   
            onChange={(e) => {setLastName(e.target.value)}}
            name="LAST NAME"
            type="text"
            placeholder="LAST NAME"
            />
            <CustomLabel label="EMAIL" />
            <CustomInput
            onChange={(e) => {setEmail(e.target.value)}}
            id="EMAIL"   
            name="EMAIL"
            type="email"
            placeholder="EMAIL"
            />
            <CustomLabel label="ROLE" />
             <select onChange={(e) => {setRole(e.target.value)}} className="block  text-black appearance-none w-full bg-white border  hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
             <option selected disabled>Select A Role</option>
              {Roles.map((role) => (
                <option value={role.role}>
                    {role.role}
                </option>
              ))}
              </select>
            <CustomLabel label="BUS STATION" />
            <select onChange={(e) => setBusId(getIdByName(e.target.value))}   className="block  text-black appearance-none w-full bg-white border  hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
             <option selected disabled>Select A Bus Station</option>
             {busStation.map((busstation) => (
                  <option  value={busstation.name}>
                   {busstation.name}
              </option>
              ))}
              </select>
            <CustomLabel label="Password" />
            <CustomInput
            id="password"   
            onChange={(e) => {setPassword(e.target.value)}}
            name="password"
            type="password"
            placeholder="******"
            />
            <CustomButton
            onClick={addHandler}
            type="submit"
            content="Add"
            />
            </>
        }
      />
    </>
  )
}

export default AddUsers
