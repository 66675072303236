import React from 'react'
import BarAdd from '../../components/Admin/BarAdd'
import GetAllFdrs from '../../components/Admin/data/GET/GetAllFdrs'



function Fdrs() {
  return (
    <div>
      <BarAdd 
        to="/AddFdr"
        title="Feuille De Route List"
        content="Add Feuille De Route +"
        style={"px-4 bg-white mr-5 py-2 rounded-lg"}
      />
      <GetAllFdrs />
    </div>
  )
}

export default Fdrs
