import React from 'react'
import BarAdd from '../../../../components/Admin/BarAdd'
import FormWrapper from '../../../../components/Admin/FormWrapper'
import CustomLabel from '../../../../components/CustomLabel'
import CustomInput from '../../../../components/CustomInput'
import CustomButton from '../../../../components/CustomButton'
import { CreateRoutes } from '../../../../Utils/Requests'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import Alert from '../../../../components/Alert'
import { useState } from 'react'

function AddRoutes() {

  const navigate = useNavigate()
  const token = Cookies.get('token')

  const [departure_city,setDeparture_city] = useState('')
  const [arrival_city,setArrival_city] = useState('')
  const [price ,setPrice] = useState(null)
  const [km,setKm] = useState(null)
  const [travel_time,setTravel_time] = useState('')
  const [error , setError] = useState(false)
  const [message, setMessage] = useState('')


  const handleDepartCity = (e) => {
    return setDeparture_city(e.target.value)
  }
  const handleArrivalCity = (e) => {
    return setArrival_city(e.target.value)
  }
  const handlePrice = (e) => {
    return setPrice(e.target.value)
  }
  const handleKm = (e) => {
    return setKm(e.target.value)
  }
  const handleTravelTime = (e) => {
    return setTravel_time(e.target.value)
  }


  const AddHandler = async (e) => {
    e.preventDefault()
    const data = {
      departure_city,
      arrival_city,
      price,
      km,
      travel_time
    }
    try {
      CreateRoutes(token,data).then(() => {
        setError(false)
        navigate('/routespages')
      }).catch(() => {
        setError(true)
        setMessage('Server Error , try Again please')
      })
    } catch (error) {
      setError(true)
      setMessage('Server Error , try Again please')
    }
  }


  return (
    <>
    <BarAdd title="Add Route" /> 
    {error && <Alert className="flex mx-auto p-4 mb-4 mt-7 w-64 text-sm text-red-800 rounded-lg bg-red-100" message={message} />}
    <FormWrapper
     title="Add Route"
     Children={
        <>
         <CustomLabel label="DEPARTURE CITY	" />
            <CustomInput
            id="DEPARTURE CITY	"   
            name="DEPARTURE CITY	"
            type="text"
            placeholder="DEPARTURE CITY	"
            onChange={handleDepartCity}
            />
             <CustomLabel label="ARRIVAL CITY	" />
            <CustomInput
            id="ARRIVAL CITY	"   
            name="ARRIVAL CITY	"
            type="text"
            placeholder="ARRIVAL CITY	"
            onChange={handleArrivalCity}
            />
             <CustomLabel label="PRICE" />
            <CustomInput
            id="PRICE"   
            name="PRICE"
            type="text"
            placeholder="PRICE"
            onChange={handlePrice}
            />
             <CustomLabel label="KM" />
            <CustomInput
            id="KM"   
            name="KM"
            type="text"
            placeholder="KM"
            onChange={handleKm}
            />
             <CustomLabel label="TRAVEL TIME" />
            <CustomInput
            type="time" 
            id="time" 
            name="time" 
            onChange={handleTravelTime}
            />
             <CustomButton
            onClick={AddHandler}
            type="submit" 
            content="Add"
            />
        </>
     }
    />      
    </>
  )
}

export default AddRoutes
