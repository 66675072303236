import React from 'react'
import BarAdd from '../../../../components/Admin/BarAdd'
import FormWrapper from '../../../../components/Admin/FormWrapper'
import CustomButton from '../../../../components/CustomButton'
import CustomInput from '../../../../components/CustomInput'
import CustomLabel from '../../../../components/CustomLabel'
import { useParams } from 'react-router-dom'
import { useState , useEffect } from 'react'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import Alert from '../../../../components/Alert'
import { UpdateBusStation , GetBusStationById } from '../../../../Utils/Requests'


function UpdateBusStationComponent() {

  // get token from Cookies 
  const token = Cookies.get('token')
  // Retrieve Values from URL and put them in placeHolder
  const { id  } = useParams()
  const [name,setNewName] = useState('')
  const [location,setNewLocation] = useState('')
  const [phone_number, setNewPhone] = useState('')
  const [email, setNewEmail] = useState('')
  const [error,setError] = useState(false)
  const [message,setMessage] = useState('')
  // to navigate after update : 
  const navigate = useNavigate()
  // Use Effect to get DATA : 
  useEffect(() => { 
    GetBusStationById(id,token).then((data) => {
      setNewName(data.name)
      setNewLocation(data.location)
      setNewPhone(data.phone_number)
      setNewEmail(data.email)
    })
  },[])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const data = {
      name,
      location,
      phone_number,
      email
    }
    if(Object.values(data).some((field) => field === " " || field === null || field === undefined)){
      setError(true)
      setMessage('please fill all fields')
      return
    }
    try {
      await UpdateBusStation(id,data,token);
      setError(false)
      navigate('/busStation')
    } catch (error) {
      setError(true)
      setMessage('Server Error Try again ...')
    }
  }

  return (
    <>
    <BarAdd title="Update Bus Station " />
    {error && <Alert className="flex mx-auto p-4 mb-4 mt-7 w-64 text-sm text-red-800 rounded-lg bg-red-100" message={message}/> }
    <FormWrapper 
    title="Update Bus Station"
    Children={
      <>
      <CustomLabel label="Name" />
      <CustomInput
      id="NameStation"
      name="NameStation"
      type="text"
      value={name}
      onChange={(e) => {setNewName(e.target.value)}}
      />
      <CustomLabel label="Location" />
      <CustomInput
      id="Location"
      name="Location"
      type="text"
      value={location}
      onChange={(e) => {setNewLocation(e.target.value)}}
      />
      <CustomLabel label="Phone Number" />
      <CustomInput
      id="Phone Number"
      name="Phone Number"
      value={phone_number}
      type="text"
      onChange={(e) => { setNewPhone(e.target.value)}}
      />
      <CustomLabel label="Email" />
      <CustomInput
      id="Email"
      name="Email"
      type="email"
      value={email}
      onChange={(e) => {setNewEmail(e.taget.value)}}
      />
      <CustomButton
                onClick={handleSubmit}
                type="submit"
                content="Update"
      />
      </>}
    />
    </>
  )
}

export default UpdateBusStationComponent
