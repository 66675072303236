import NoData from "../Assets/Images/Aucun.png";
import { GetTripById, CreateTripTickets , CreateTicketsWithIncrementedSeats} from "../Utils/Requests";
import { useState, useEffect, useContext } from "react";
import CustomButton from "./CustomButton";
import Cookies from "js-cookie";
import TripIdContext from "../hooks/TripIdContext";
import ChoosenItinerary from "../hooks/ChoosenItinerary"
import toast, { Toaster } from "react-hot-toast";
import LoadingSpinner from "./LoadingSpinner";
import { useNavigate, useParams } from "react-router-dom";
import DateConvertion from "../helpers/Date";

function Ticket() {
  const { datetime } = useParams();
  const DATEFORMATTED = DateConvertion(datetime);
  const { min_seats } = useParams();
  const [place, setPlace] = useState(+min_seats);
  const [unitPrice, setUnitPrice] = useState("");
  // price;
  const [price, setPrice] = useState("");
  const token = Cookies.get("token");
  // States for DATA fetched :
  const [dataFetched, setDataFetched] = useState([]);

  // State for Loading Spinner :
  const [open, setOpen] = useState(false);

  // for set data in context : setTripId
  // for retrieve data state : TripId
  // To see State if we have  A TRIP IR OR NOT : showData , setShowData
  const {
    setTripId,
    TripId,
    showData,
    setShowData,
    setQuantity,
    shouldRefetch,
    setShouldRefetch,
  } = useContext(TripIdContext);

  const {dataItinerary,setDataItinerary} = useContext(ChoosenItinerary)
  // Place Counter :
  const Plus = () => {
    setPlace(place + 1);
    setQuantity((prev) =>
      prev.map((item) =>
        item.id === TripId ? { id: item.id, quantity: item.quantity + 1 } : item
      )
    );
  };
  const Moins = () => {
    setPlace(place - 1);
    setQuantity((prev) =>
      prev.map((item) =>
        item.id === TripId ? { id: item.id, quantity: item.quantity - 1 } : item
      )
    );
  };
  // State's For payment details :
  
  const [err,setErr] = useState(false)
  const [payment, setPayment] = useState("cash");
  const [notYetDone, setNotYetDone] = useState(true);
  const [isDone, setIsDone] = useState(false);
  // function to get value selected :
  const handleOptionChange = (event) => {
    setPayment(event.target.value);
  };

  const navigate = useNavigate();

  // Send API :

  useEffect(() => {
    try {
      GetTripById(TripId, token)
        .then((data) => {
          setUnitPrice(data.price);
          setDataFetched(data);
        })
        .catch(() => {
          setErr(true)
        });
      setPlace(+min_seats);
    } catch (error) {
      setErr(true)
    }
  }, [TripId, price]);

  function handleIsValidPayment(payment) {
    const number_of_seats = place;
    if (payment === "cash") {
      setOpen(true);
      CreateTicketsWithIncrementedSeats(token,TripId, number_of_seats)
        .then((data) => {
          console.log(data);
          setNotYetDone(false);
          setIsDone(true);
        })
        .catch(() => {
          setErr(true)
        });
    } else if (payment === "tpe") {
      console.log("tpe API");
    }
    setTimeout(() => {
      setShouldRefetch(true);
      setQuantity((prev) => prev.filter((item) => item.id !== TripId));
      setTripId(" ");
      setShowData(false);
      setPayment("cash");
      setPlace(+min_seats);
      setOpen(false);
      navigate("/searchtrips");
    }, 3000);
  }

  // function to ignore trip and set toast :
  function cancelTrip(id) {
    setTripId(" ");
    setShowData(false);
    setPayment("cash");
    setPlace(+min_seats);
    setQuantity((prev) => prev.filter((item) => item.id !== id));
    toast.error("Select de Voyage annuler !", {
      position: "bottom-right",
    });
    setDataItinerary([])
  }
  return (
    <>
      <LoadingSpinner open={open} />
      {showData && (
        <div className="flex flex-col">
          <div className="bg-red-600 text-white my-2 py-2 mx-2 px-2 text-center">
          <p>{dataItinerary.join(' - ')}</p>
          </div>
          <p
            className="mt-10 mb-3 mx-auto"
            style={{ fontSize: "25px", fontWeight: "bold" }}
          >
            Réservation Du Billet
          </p>
          <p className="mx-auto my-2">
            Ticket Du Bus de{" "}
            <span className="font-bold text-red-600">
              {dataFetched.departure_city}
            </span>{" "}
            et a destination de :{" "}
            <span className="font-bold text-red-600">
              {dataFetched.arrival_city}
            </span>{" "}
          </p>
          <p className="mx-auto font-extrabold border-2 px-4 bg-red-700 text-white">
            {DATEFORMATTED}
          </p>

          {/* Ste transport */}
          <div className="allContent my-3 flex mx-5 ">
            <p className="font-bold" style={{ fontSize: "19px" }}>
              {" "}
              Ste transport :
            </p>
            <p className="text-red-600 mx-3 " style={{ fontSize: "19px" }}>
              {dataFetched.bus_company_name}
            </p>
          </div>
          {/* date */}
          <div className="allContent my-3 flex mx-5 ">
            <p className="font-bold" style={{ fontSize: "19px" }}>
              {" "}
              Heure D'épart :
            </p>
            <p className="text-red-600 mx-3" style={{ fontSize: "19px" }}>
              {dataFetched.departure_time}
            </p>
          </div>
          {/* date */}
          <div className="allContent my-3 flex mx-5 ">
            <p className="font-bold" style={{ fontSize: "19px" }}>
              {" "}
              Heure D’arrivé :{" "}
            </p>
            <p className="text-red-600 mx-3" style={{ fontSize: "19px" }}>
              {dataFetched.arrival_time}
            </p>
          </div>
          {/* nombre de place */}
          <div className="allContent my-3 flex mx-5 ">
            <p className="font-bold" style={{ fontSize: "19px" }}>
              {" "}
              Nombre de place(s) :
            </p>
            <p className="text-red-600 mx-3" style={{ fontSize: "19px" }}>
              {place <= 1 ? (
                <></>
              ) : (
                <button
                  onClick={Moins}
                  className="mx-3 border border-red-700 px-2 rounded-md hover:bg-red-700 hover:text-white"
                >
                  -
                </button>
              )}
              {place}
              {place === dataFetched.availble_seats ? (
                <></>
              ) : (
                <button
                  onClick={Plus}
                  className="mx-3 border px-2 border-red-700 rounded-md hover:bg-red-700 hover:text-white"
                >
                  +
                </button>
              )}
            </p>
          </div>
          {/* Prix Total  */}
          <div className="allContent my-3 flex mx-5 ">
            <p className="font-bold" style={{ fontSize: "19px" }}>
              {" "}
              Prix :
            </p>
            <p
              className="text-red-600 font-bold mx-3 text-1xl"
              style={{ fontSize: "19px" }}
            >
              {unitPrice * place}
            </p>
          </div>

          {/* Payment  */}
          <div className="allContent my-3 flex mx-5 ">
            <p className="font-bold" style={{ fontSize: "19px" }}>
              {" "}
              Paiment :
            </p>
            <p className="text-yellow-400 font-bold mx-3 text-1xl">
              {payment ? (
                <p className="text-green-700" style={{ fontSize: "19px" }}>
                  {payment}
                </p>
              ) : (
                <p className="text-orange-500" style={{ fontSize: "19px" }}>
                  En Attente
                </p>
              )}
            </p>
          </div>

          {/* payment options */}
          <div>
            <div className="flex items-center my-3  mx-8">
              <input
                value="cash"
                checked={payment == "cash"}
                onChange={handleOptionChange}
                name="cash"
                id="default-radio-1"
                type="radio"
                className="text-red-500"
              />
              <label
                htmlFor="default-radio-1"
                className="ml-2 text-sm font-medium text-gray-900"
                style={{ fontSize: "19px" }}
              >
                Paiement Cash
              </label>
            </div>
            <div className="flex items-center mx-8">
              <input
                value="tpe"
                checked={payment == "tpe"}
                onChange={handleOptionChange}
                name="tpe"
                id="default-radio-2"
                type="radio"
                className="w-4 h-4 text-red-600 bg-gray-100 border-gray-300 focus:ring-2"
              />
              <label
                htmlFor="default-radio-2"
                className="ml-2 text-sm font-medium text-gray-900"
                style={{ fontSize: "19px" }}
              >
                Paiement Avec Carte Banquaire
              </label>
            </div>
          </div>
          {/* btns */}
          <div className="flex mt-10 mx-auto gap-6">
            <CustomButton
              type="submit"
              onClick={() => {
                handleIsValidPayment(payment);
              }}
              content="Acheter Ticket"
            />
            <button
              onClick={() => {
                cancelTrip(TripId);
              }}
              className=" inline-block  rounded border border-[#EA022E]  px-16 py-3.5 text-sm font-medium text-red-600 hover:bg-red-600 hover:text-white"
            >
              Annuler
            </button>
          </div>
          <Toaster />
        </div>
      )}

      {/* // No data  */}

      {!showData && (
        <div
          className="flex flex-col  items-center"
          style={{ height: "38rem" }}
        >
          <p className="my-16" style={{ fontSize: "25px", fontWeight: "bold" }}>
            Réservation Du Billet{" "}
          </p>
          <img style={{ width: "23rem" }} src={NoData} alt="" />
          <p className="text-center my-2 font-light">
            Aucun Billet N’est <br />
            Sélectionné !!
          </p>
        </div>
      )}

      {/* // With Data :  */}
    </>
  );
}

export default Ticket;
