import Api from "./Api";



// Login Function
export async function Login(user) {
  user["grant_type"] = "password";

  const config = {
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
  };

  const res = await Api.post("/token", user, config);
  return res.data;
}

// search trips :
export async function SearchTrips(city,dateTime,token,min_seats){
  const res = await Api.get(`/trips/trips/${city}/${dateTime}?min_seats=${min_seats}` , {
      headers: {
           'Authorization': `Bearer ${token}`,
           'content-type' : "application/x-www-form-urlencoded"
      }
  } )
  return res.data;
}

// Get Single Trip for tickets : 
export async function GetTripById(id,token){
    const res = await Api.get(`/trips/trips/${id}` , {
      headers: {
        'Authorization': `Bearer ${token}`,
        'content-type' : "application/x-www-form-urlencoded"
      }
    })
    return res.data
}
// Create Trip with a Trip_ID and token for headers and Number place as parametre: 
export async function CreateTripTickets(TripId,token,number_of_seats){

    const config = {
      headers: { Authorization : `Bearer ${token}`}
    }
    const params = {
      number_of_seats:number_of_seats
    }
    const res = await Api.post(`/trips/trips/${TripId}/tickets` , {}  , { params:params , headers: config.headers })
    return res.data
}


// SuperAdmin : 
// Add Bus Company :
// required [token , data ]
export async function CreateBusCompany(token,data){
  const config = {
    headers: { Authorization : `Bearer ${token}`
  }

  }
  const res = await Api.post(`/bus_companies/bus_companies/` , data , config)
  return res.data
}

// Get All Bus Company :
// required [token]
export async function GetAllCompanies(token){
  const config = {
    headers: { Authorization : `Bearer ${token}`}
  }
  const res = await Api.get(`/bus_companies/bus_companies/` , config)
  return res.data
}

// delete Bus Company : 
// required [token],id
export async function DeleteCompany(token,id){
  const config = {
    headers: { Authorization : `Bearer ${token}`}
  }
  const res = await Api.delete(`/bus_companies/bus_companies/${id}` , config)
  return res.data
}

// Update Bus Company : 
// required [token],id
export async function UpdateCompany(token,id,data){
  const config = {
    headers: { Authorization : `Bearer ${token}`}
  }
  const res = await Api.put(`/bus_companies/bus_companies/${id}`, data , config)
  return res.data
}

// Get Signle Bus Company : 
// required [token , id]
export async function GetSingleCompany(token,id){
  const config = {
    headers : { Authorization : `Bearer ${token}` }
  }
  const res = await Api.get(`/bus_companies/bus_companies/${id}` , config)
  return res.data
}


/* ============ Buses ============ */

const getAllBuses = async (token) => {
  const config = {
    headers: { Authorization : `Bearer ${token}` }
  }
  const res = await Api.get("/buses/buses", config);
  return res.data;
}

const getBusById = async (id, token) => {
  const config = {
    headers: { Authorization : `Bearer ${token}` }
  }
  const res = await Api.get(`/buses/buses/${id}`, config);
  return res.data;
}

const createBus = async (bus, token) => {
  const config = {
    headers: { Authorization : `Bearer ${token}` }
  }
  const res = await Api.post("/buses/buses", bus, config);
  return res.data;
}

const updateBus = async (id, bus, token) => {
  const config = {
    headers: { Authorization : `Bearer ${token}` }
  }
  const res = await Api.put(`/buses/buses/${id}`, bus, config);
  return res.data;
}

const deleteBus = async (id, token) => {
  const config = {
    headers: { Authorization : `Bearer ${token}` }
  }
  const res = await Api.delete(`/buses/buses/${id}`, config);
  return res.data;
}

export { getAllBuses, getBusById, createBus, updateBus, deleteBus };

/* ================================ */


// Bus Stattion endpoints :

// Add Bus Station : 
// Required : [token , data ]
export async function CreateBusStation(token,data){
  const config = {
    headers: { Authorization : `Bearer ${token}`
  }

  }
  const res = await Api.post(`/bus_stations/bus_stations/` , data , config)
  return res.data
}


// Get All Buses Station
// Required : [token]
export async function GetAllBusStation(token){
  const config = {
    headers: { Authorization : `Bearer ${token}`}
  }
  const res = await Api.get(`/bus_stations/bus_stations/` , config)
  return res.data
}

// Get Single Bus By ID : 
// required  [token , id ]
export async function GetBusStationById(id,token){
  const config = {
    headers : {
     Authorization: `Bearer ${token}`
    }
    }
  const res = await Api.get(`/bus_stations/bus_stations/${id}` , config)
  return res.data
}

// Update Bus By ID :
// required : [token , id]
export async function UpdateBusStation(id,data,token){
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const res = await Api.put(`bus_stations/bus_stations/${id}` , data , config)
  return res.data
}


// Delete Bus Station
// required [token , id]
export async function DeleteBusStation(id,token){
  const config = {
    headers : {
     Authorization: `Bearer ${token}`
    }
}
const res = await Api.delete(`/bus_stations/bus_stations/${id}` , config)
return res.data
}




//  ------- Routes Endpoints---------- : 


// Create Routes : 
// required [token , data]
export async function CreateRoutes(token,data){
  const config = {
    headers : {
      Authorization : `Bearer ${token}`
    }
  }
  const res = await Api.post('/routes/routes/' , data , config)
  return res.data
}


// Get All  Routes : 
// required [token]
export async function GetAllRoutes(token){
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const res = await Api.get('/routes/routes', config)
  return res.data
}


// Get Single Route : 
export async function GetRouteById(token,id){
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const res = await Api.get(`/routes/routes/${id}` , config)
  return res.data
}

// delete Route : 
// required [token , id]
export async function DeleteRoute(token,id){
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const res = await Api.delete(`/routes/routes/${id}`,config)
  return res.data
}


// Update Route : 
// required [token , id , data]
export async function UpdateRoute(id,token,data){
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const res = await Api.put(`/routes/routes/${id}` , data , config)
  return res.data
}




//  ------- Users Endpoints---------- : 


// Create User : 
// required [token , data]

export async function CreateUser(token,data){
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const res = await Api.post('/users/users/' , data , config)
  return res.data
}

// Get All Users : 
// required [token]

export async function GetAllUsers(token){
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const res = await Api.get('/users/users/' , config)
  return res.data
}

// Get Single User 
// required [id,token]

export async function GetSingleUser(id,token){
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  const res = await Api.get(`/users/users/${id}` , config)
  return res.data
}

// Delete User : 
// required [id,token]

export async function DeleteSingleUser(id,token){
  const config = {
    headers: {
      Authorization : `Bearer ${token}`
    }
  }
  const res = await Api.delete(`/users/users/${id}` , config)
  return res.data
}


// Update Users : 
// required [id,token]
export async function UpdateSingleUser(id,token,data){
  const config = {
    headers: {
      Authorization : `Bearer ${token}`
    }
  }
  const res = await Api.put(`/users/users/${id}` , data , config)
  return res.data
}



// endpoint Return List of cities : 
export async function GetListOfCities(token){
  const config = {
    headers: {
      Authorization : `Bearer ${token}`
    }
  }
  const res = await Api.get(`/trips/cities` , config)
  return res.data
}


/* ============ Agreements ============ */

const getAllAgreements = async (token) => {
  const config = {
    headers: { Authorization : `Bearer ${token}` }
  }
  const res = await Api.get("/agreements/agreements", config);
  return res.data;
}

const getAgreementById = async (id, token) => {
  const config = {
    headers: { Authorization : `Bearer ${token}` }
  }
  const res = await Api.get(`/agreements/agreements/${id}`, config);
  return res.data;
}

const createAgreement = async (agreement, token) => {
  const config = {
    headers: { Authorization : `Bearer ${token}` }
  }
  const res = await Api.post("/agreements/agreements", agreement, config);
  return res.data;
}

const updateAgreement = async (id, agreement, token) => {
  const config = {
    headers: { Authorization : `Bearer ${token}` }
  }
  const res = await Api.put(`/agreements/agreements/${id}`, agreement, config);
  return res.data;
}

const deleteAgreement = async (id, token) => {
  const config = {
    headers: { Authorization : `Bearer ${token}` }
  }
  const res = await Api.delete(`/agreements/agreements/${id}`, config);
  return res.data;
}

const getBusesFromAgreement = async (id, token) => {
  const config = {
    headers: { Authorization : `Bearer ${token}` }
  }
  const res = await Api.get(`/agreements/agreements/${id}/buses`, config);
  return res.data;
}

const addBusesToAgreement = async (id, buses, token) => {
  const config = {
    headers: { Authorization : `Bearer ${token}` }
  }
  const res = await Api.put(`/agreements/agreements/${id}/buses`, buses, config);
  return res.data;
}

const deleteBusesFromAgreement = async (id, buses, token) => {
  const config = {
    headers: { Authorization : `Bearer ${token}` }
  }
  const res = await Api.delete(`/agreements/agreements/${id}/buses`, { data: buses, headers: config.headers });
  return res.data;
}

export {
  getAllAgreements,
  getAgreementById,
  createAgreement,
  updateAgreement,
  deleteAgreement,
  getBusesFromAgreement,
  addBusesToAgreement,
  deleteBusesFromAgreement,
};

/* ================================ */


/* ============ Allowed routes ============ */

const getAllowedRoutes = async (token) => {
  const config = {
    headers: { Authorization : `Bearer ${token}` }
  }
  const res = await Api.get("/allowed_routes/allowed_routes", config);
  return res.data;
}

const getAllowedRouteById = async (id, token) => {
  const config = {
    headers: { Authorization : `Bearer ${token}` }
  }

  const res = await Api.get(`/allowed_routes/allowed_routes/${id}`, config);
  return res.data;
}

const createAllowedRoute = async (allowedRoute, token) => {
  const config = {
    headers: { Authorization : `Bearer ${token}` }
  }

  const res = await Api.post("/allowed_routes/allowed_routes", allowedRoute, config);
  return res.data;
}

const updateAllowedRoute = async (id, allowedRoute, token) => {
  const config = {
    headers: { Authorization : `Bearer ${token}` }
  }

  const res = await Api.put(`/allowed_routes/allowed_routes/${id}`, allowedRoute, config);
  return res.data;
}

const deleteAllowedRoute = async (id, token) => {
  const config = {
    headers: { Authorization : `Bearer ${token}` }
  }

  const res = await Api.delete(`/allowed_routes/allowed_routes/${id}`, config);
  return res.data;
}

const getAllowedRouteAgreement = async (id, token) => {
  const config = {
    headers: { Authorization : `Bearer ${token}` }
  }

  const res = await Api.get(`/allowed_routes/allowed_routes/${id}/agreements`, config);
  return res.data;
}

const getAllowedRouteFdr = async (id, token) => {
  const config = {
    headers: { Authorization : `Bearer ${token}` }
  }

  const res = await Api.get(`/allowed_routes/allowed_routes/${id}/fdr`, config);
  return res.data;
}

export {
  getAllowedRoutes,
  getAllowedRouteById,
  createAllowedRoute,
  updateAllowedRoute,
  deleteAllowedRoute,
  getAllowedRouteAgreement,
  getAllowedRouteFdr,
};

/* ======================================== */




// Fdrs Endpoint : 


// Get All Fdrs : 
// Required [token]
export async function GetAllFeuille(token){
  const config = {
    headers: { Authorization : `Bearer ${token}`}
  }
  const res = await Api.get('/fdrs/fdrs/' , config )
  return res.data
}


// create feuille de route from Allowed Routes : 
export async function CreateFdr(token,id,date){
  const config = {
    headers: { Authorization : `Bearer ${token}`}
  }
  
  const res = await Api.post(`/allowed_routes/allowed_routes/${id}/fdr/${date}`, { } , config)
  return res.data
}

// delete feuille De route by ID : 
export async function Deletefdr(id,token){
  const config = {
    headers: { Authorization: `Bearer ${token}`}
  }
  const res = await Api.delete(`/fdrs/fdrs/${id}`, config)
  return res.data
}

// getSinglefDR / 
export async function GetSingleFdr(id,token){
  const config = {
    headers: { Authorization : `Bearer ${token}`}
  }
  const res = await Api.get(`/fdrs/fdrs/${id}`,  config)
  return res.data
}


// Update fdr : 
export async function UpdateFdr(id,token,data){
  const config = {
    headers : { Authorization : `Bearer ${token}`}
  }
  const res = await Api.put(`/fdrs/fdrs/${id}` , data ,  config)
  return res.data
}


// Add Trip from Fdr :
export async function AddTripFromFdr(id,token){
  const config = {
    headers : { Authorization : `Bearer ${token}`}
  }
  const res = await Api.post(`/fdrs/fdrs/trips/?fdr_id=${id}` , {} , config)
  return res.data
}


/* ============ Trips ============ */

const getAllTrips = async (token) => {
  const config = {
    headers: { Authorization : `Bearer ${token}` }
  }
  const res = await Api.get("/trips/trips", config);
  return res.data;
}

const getTripById = async (id, token) => {
  const config = {
    headers: { Authorization : `Bearer ${token}` }
  }
  const res = await Api.get(`/trips/trips/${id}`, config);
  return res.data;
}

const createTrip = async (trip, token) => {
  const config = {
    headers: { Authorization : `Bearer ${token}` }
  }
  const res = await Api.post("/trips/trips", trip, config);
  return res.data;
}

const updateTrip = async (id, trip, token) => {
  const config = {
    headers: { Authorization : `Bearer ${token}` }
  }
  const res = await Api.put(`/trips/trips/${id}`, trip, config);
  return res.data;
}

const deleteTrip = async (id, token) => {
  const config = {
    headers: { Authorization : `Bearer ${token}` }
  }
  const res = await Api.delete(`/trips/trips/${id}`, config);
  return res.data;
}

export {
  getAllTrips,
  getTripById,
  createTrip,
  updateTrip,
  deleteTrip,
};

/* ================================= */


// REQUEST PRINTERS : 

// Get All Printers IP with Users IP
export async function GetAllPrinters(token){
  const config = {
    headers : { Authorization : `Bearer ${token}`}
  }
  const res = await Api.get('/printers/printers/', config)
  return res.data
}


// DELETE PRINTER BY USER_IP
export async function DeletePrinterByID(token,user_ip){
  const config = {
    headers : { Authorization : `Bearer ${token}`}
  }
  const res = await Api.delete(`/printers/printers/${user_ip}` , config)
  return res.data
}

// Update PRINTER BY USER_IP

export async function UpdatePrinterByID(token,data,user_ip){
  const config = {
    headers : { Authorization : `Bearer ${token}`}
  }
  const res = await Api.put(`/printers/printers/?user_ip=${user_ip}`, data , config)
  return res.data 
}

// ADD PRINTER 

export async function AddPrinter(token,data){
  const config = {
    headers : { Authorization : `Bearer ${token}`}
  }
  const res = await Api.post('/printers/printers', data , config)
  return res.data
}


// Finalize Fdr :
export async function FinalizeFdrr(token,id){
  const config = {
    headers : { Authorization : `Bearer ${token}`}
  }
  const res = await Api.get(`fdrs/fdrs/finalize/${id}`, config)
  return res.data
}
// Finalize Fdr PDF :
export async function FinalizeFdrPdf(token,id){
  const config = {
    headers : { Authorization : `Bearer ${token}`},
    responseType: 'blob'

  }
  const res = await Api.get(`fdrs/fdrs/finalize/${id}/pdf` , config)
  return res.data;
}


// Get Seats By BUD ID : 
export async function GetSeatsByTripId(token,bus_id){
  const config = {
    headers : { Authorization : `Bearer ${token}`}
  }
  const res = await Api.get(`/seats/seats/bus/${bus_id}` , config)
  return res.data
}

export async function GetFdrTodayDate(token){
  const config = {
    headers : { Authorization : `Bearer ${token}`}
  }
  const res = await Api.get(`/fdrs/fdrs/today/`, config)
  return res.data
}

export async function CreateTicketsWithIncrementedSeats(token,trip_id,number_of_seats){
   const config = {
    headers : { Authorization : `Bearer ${token}`}
  }
  const res = await Api.post(`/trips/trips/${trip_id}/tickets_V2?number_of_seats=${number_of_seats}`,{},config)
  return res.data
}

export async function CreateTicketWithDataSeats(token,trip_id,seat_numbers){
  const config = {
    headers : { Authorization : `Bearer ${token}`}
  }
  const res = await Api.post(`/trips/trips/${trip_id}/seat_tickets/chosen_seats`,seat_numbers,config)
  return res.data
}


export async function GetTripsFromFdrId(token,id){
  const config = {
    headers: { Authorization : `Bearer ${token}`}
  }
  const res = await Api.get(`/fdrs/fdrs/${id}/trips/` , config)
  return res.data
}