import React , { useState , useEffect} from 'react'
import BarAdd from '../../../../components/Admin/BarAdd'
import FormWrapper from '../../../../components/Admin/FormWrapper'
import CustomButton from '../../../../components/CustomButton'
import CustomInput from '../../../../components/CustomInput'
import CustomLabel from '../../../../components/CustomLabel'
import { AddPrinter } from '../../../../Utils/Requests'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import Alert from '../../../../components/Alert'

function AddIpUser() {

  const token = Cookies.get('token')
  const nav = useNavigate()

  const [user_ip , setUserIP] = useState('')
  const [printer_ip, setPrinterIP] = useState('')
  const [isError , setIsError] = useState(false)
  const [messaegeErr, setMessageErr] = useState('')

  const addHandler = (e) => {
    e.preventDefault()
    const data = {
      user_ip,
      printer_ip
    }
    if(Object.values(data).some((field) => field === " " || field === null || field === undefined)){
      setIsError(true)
      setMessageErr('please fill all fields')
      return
    }
    try {
      AddPrinter(token,data).then(() => {
        setIsError(false)
        nav('/Printers')
      }).catch(() => {
        setIsError(true)
        setMessageErr('Server Error , Try Again')
      })
    } catch (error) {
      setIsError(true)
      setMessageErr('Server Error , Try Again')
    }
  }


  return (
    <>
      <BarAdd title="Add PRINTER"  /> 
      {isError && <Alert className="flex mx-auto p-4 mb-4 mt-7 w-64 text-sm text-red-800 rounded-lg bg-red-100" message={messaegeErr} />}
      <FormWrapper 
      title="Add PRINTER"
      Children={
      <>
      <CustomLabel label="User Ip" />
      <CustomInput 
      id="user_ip"
      name="user_ip"
      type="text"
      placeholder="USER IP"
      onChange={(e) => {setUserIP(e.target.value)}}
      />
      <CustomLabel label="Printer Ip" />
      <CustomInput 
      id="printer_ip"
      name="printer_ip"
      type="text"
      placeholder="PRINTER IP"
      onChange={(e) => {setPrinterIP(e.target.value)}}
      />
      <CustomButton 
      type="submit"
      content="Add Printer"
      onClick={addHandler}
      />
      </>}
      />
    </>
  )
}

export default AddIpUser
