import React from 'react'
import TableFdrToday from '../../components/CashierAdvanced/TableFdrToday'
import BarAdd from '../../components/Admin/BarAdd'

function FeuilleDeRoute() {
  return (
    <div>
      <BarAdd title="Today Feuille De Route" />
      <div className='mt-4'>
      <TableFdrToday />
      </div>
    </div>
  )
}

export default FeuilleDeRoute
