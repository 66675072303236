import React from 'react'
import BarAdd from '../../components/Admin/BarAdd'
import GetLogs from '../../components/Admin/data/GET/GetLogs'


const Logs = () => {
  return (
    <div>
      <BarAdd title="Logs List" style={"px-4 bg-white mr-5 py-2 rounded-lg"} />
      <GetLogs />
    </div>
  )
}

export default Logs
