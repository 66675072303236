import { useEffect , useState } from "react";
import { Form, useNavigate , useParams } from "react-router-dom";
import BarAdd from "../../../../components/Admin/BarAdd";
import FormWrapper from "../../../../components/Admin/FormWrapper";
import Alert from "../../../../components/Alert";
import CustomButton from "../../../../components/CustomButton";
import CustomInput from "../../../../components/CustomInput";
import CustomLabel from "../../../../components/CustomLabel";
import Cookies from "js-cookie";
import { GetSingleFdr , UpdateFdr } from '../../../../Utils/Requests'


function PutFdr() {


    const navigate = useNavigate()

    const token = Cookies.get('token')
    const  {id} = useParams()
    const [status, setStatus] = useState()

    function getById(){
        try {
            GetSingleFdr(id,token).then((data) => {
                setStatus(data.status)
            }).catch((err) => {
                console.log(err);
            })
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getById()
    }, [])


    const UpdateHandler = (e) => {
        e.preventDefault()
        const data = {
            status
        }
        try {
            UpdateFdr(id,token,data).then(() => {
                navigate('/FeuilleDeRoute')
            }).catch((err) => {
                console.log(err);
            })
        } catch (error) {
            console.log(error)
        }
    }


  return (
    <>
    <BarAdd title="Update Feuille De Route" />
    <FormWrapper 
    title="Update feuille De Route"
    Children={<>
    {/* status */}
    <CustomLabel label="Status" />
    <select onChange={(e) => {setStatus(e.target.value)}} className="block  text-black appearance-none w-full bg-white border  hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
        <option selected disabled className="font-bold">{status ? <p>true</p> : <p>false</p>}</option>
        <option className="text-green-500" value="true">
        true
        </option>
        <option className="text-red-500" value="false">
        false  
        </option>
    </select>
    <CustomButton onClick={UpdateHandler} type="submit" content="update" />
    </>}
    />
    </>
  ) 
}

export default PutFdr



