import React, { useEffect } from 'react'
import BarAdd from '../../../../components/Admin/BarAdd'
import FormWrapper from '../../../../components/Admin/FormWrapper'
import CustomButton from '../../../../components/CustomButton'
import CustomInput from '../../../../components/CustomInput'
import CustomLabel from '../../../../components/CustomLabel'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import { UpdateRoute , GetRouteById } from '../../../../Utils/Requests'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import Alert from '../../../../components/Alert'
import './RouteUpdateCs.css'



function RouteUpdate() {

  // hook params to retrieve data from URL : 
  const params = useParams()
  const route_id = params.id

  // get Token from Cookies
  const token = Cookies.get('token')

  
  // navigate :
  const navigate = useNavigate()

  // initial states : 
  const [departure_city,setDeparture_city] = useState('')
  const [arrival_city,setArrival_city] = useState('')
  const [price ,setPrice] = useState(null)
  const [km,setKm] = useState(null)
  const [travel_time,setTravel_time] = useState('')
  const [error , setError] = useState(false)
  const [message, setMessage] = useState('')


  // getData Route BY ID :

  useEffect(() => {
    try {
      GetRouteById(token,route_id).then((data) => {
        setDeparture_city(data.departure_city)
        setArrival_city(data.arrival_city)
        setPrice(data.price)
        setKm(data.km)
        setTravel_time(data.travel_time)
      }).catch((err) => {
        console.log(err);
      })
    } catch (error) {
        console.log(error);
    }
  }, [])


  // handle All Inputs : 
  const handleDepartCity = (e) => {
    return setDeparture_city(e.target.value)
  }
  const handleArrivalCity = (e) => {
    return setArrival_city(e.target.value)
  }
  const handlePrice = (e) => {
    return setPrice(e.target.value)
  }
  const handleKm = (e) => {
    return setKm(e.target.value)
  }
  const handleTravelTime = (e) => {
    return setTravel_time(e.target.value)
  }


  // function Update : 
  const UpdateRoutes = async (e) => {
    e.preventDefault()
    const data = {
      departure_city,
      arrival_city,
      price,
      km,
      travel_time,
      route_id
    }
    try {
      UpdateRoute(route_id,token,data).then((data) => {
        navigate('/routespages')
        setError(false)
      }).catch(() => {
        setError(true)
        setMessage('Server Error , please try Again !')
      })
    } catch (error) {
        setError(true)
        setMessage('Server Error , please try Again !')
    }
  }

  return (
    <>
    <BarAdd title="Update Route" />
    {error && <Alert className="flex mx-auto p-4 mb-4 mt-7 w-64 text-sm text-red-800 rounded-lg bg-red-100" message={message}/>}
    <FormWrapper 
        title="Update Route"
        Children={
            <>
            <CustomLabel label="DEPARTURE CITY	" />
            <CustomInput
            id="DEPARTURE CITY	"   
            name="DEPARTURE CITY	"
            type="text"
            value={departure_city}
            onChange={handleDepartCity}
            />
             <CustomLabel label="ARRIVAL CITY	" />
            <CustomInput
            id="ARRIVAL CITY	"   
            name="ARRIVAL CITY	"
            type="text"
            value={arrival_city}
            onChange={handleArrivalCity}
            />
             <CustomLabel label="PRICE" />
            <CustomInput
            id="PRICE"   
            name="PRICE"
            type="text"
            value={price}
            onChange={handlePrice}
            />
             <CustomLabel label="KM" />
            <CustomInput
            id="KM"   
            name="KM"
            type="text"
            onChange={handleKm}
            value={km}
            />
             <CustomLabel label="TRAVEL TIME" />
            <CustomInput
            type="time" 
            id="time" 
            name="time" 
            value={travel_time}
            onChange={handleTravelTime}
            />
             <CustomButton
            onClick={UpdateRoutes}
            type="submit" 
            content="Update"
            />
            </>
        }
    />
    </>
  )
}

export default RouteUpdate
